<template>
  <div class="manageSider">
    <a-menu theme="dark" mode="inline"
            @openChange = "onOpenChange"
            :selectedKeys="[$route.path]"
            v-model=select
            :open-keys="openKeys"
    >
      <a-sub-menu :key="menu.id" v-for="menu in menus">
        <span slot="title">
          <!-- <span class="ant-pro-menu-item-title">{{ menu.name }}</span> -->
          <div style="display: flex;align-items: center;width:300px;transform: translateX(-12px);">
              <div alt="" :style="{'background':menu.color}" class="menuLIst">
                {{ menu.name.substring(0,1) }}
              </div>
              <span>
                {{ menu.name }}
              </span>
            </div>
        </span>
  
        
        <a-menu-item :key="menuChildren.path"  v-for="menuChildren in menu.childMenu"  @click="menuTitle(menuChildren.name)">
          <router-link :to="menuChildren.path">
            <span>{{menuChildren.name}}</span>
          </router-link>
        </a-menu-item>
      </a-sub-menu>
    </a-menu>  
  </div>
  </template>
  
  <script>
  import {admin_icon} from '@/utils/myIcon'
  import {manageMenus,getfirstMenu,getSecondThreeMenu} from '@/service/menu_api'
  export default {
    name: "ManageSider",
    components:{
      admin_con: admin_icon,
    },
    props:["collapsed"],
    data(){
      return{
        img:'https://yunicu-1252497858.cos.ap-guangzhou.myqcloud.com/images/1620376125128_2.png',
        menus:[
          // {
          //   childMenu:[
          //     {id:336,name:"页面访问记录",parentId:98,path:"/appDataExport"},
          //     {id:337,name:"个人主页访问记录",parentId:98,path:"/PersonalHomepageAccessHistory"},
          //   ],
          //   icon:"https://yun-new-admin-1252497858.cos.ap-guangzhou.myqcloud.com/images/c9e178f4-f55d-49c5-8d63-0cdfc6be5961.png",
          //   id:98,
          //   name:"界面浏览数据导出",
          //   parentId:0,
          //   path:"#"
          // },
          // {
          //   childMenu:[
          //     {id:338,name:"点播内容浏览记录",parentId:99,path:"/vodLog"},
          //     {id:339,name:"医学杂志内容浏览记录",parentId:99,path:"/magazineRecord"},
          //     {id:340,name:"文献指南内容浏览记录",parentId:99,path:"/BrowsingHistory"},
          //     {id:341,name:"进修招聘内容浏览记录",parentId:99,path:"/EduRecContentClick"},
          //     {id:343,name:"药品查询内容浏览记录",parentId:99,path:"/DrugContentClick"},
          //     {id:342,name:"文章浏览记录",parentId:99,path:"/articleLog"},
          //     {id:344,name:"社区帖子浏览记录",parentId:99,path:"/homeReadRecord"},
          //     {id:344,name:"医学公式浏览记录",parentId:99,path:"/UnitConversionBrowsingHistory"},
          //   ],
          //   icon:"https://yun-new-admin-1252497858.cos.ap-guangzhou.myqcloud.com/images/c9e178f4-f55d-49c5-8d63-0cdfc6be5961.png",
          //   id:99,
          //   name:"内容浏览数据导出",
          //   parentId:0,
          //   path:"#"
          // },
          // {
          //   childMenu:[
          //     {id:345,name:"收藏记录",parentId:100,path:"/conCollectionLog"},
          //     {id:346,name:"评论记录",parentId:100,path:"/conCommentsLog"},
          //     {id:347,name:"点赞记录",parentId:100,path:"/conThumbsLog"},
          //     {id:348,name:"分享记录",parentId:100,path:"/conShareLog"},
          //     {id:349,name:"发送邮件记录",parentId:100,path:"/SendingEmailRecords"},
          //     {id:351,name:"搜索记录查询",parentId:100,path:"/searchRecord"},
          //   ],
          //   icon:"https://yun-new-admin-1252497858.cos.ap-guangzhou.myqcloud.com/images/c9e178f4-f55d-49c5-8d63-0cdfc6be5961.png",
          //   id:100,
          //   name:"会员行为记录导出",
          //   parentId:0,
          //   path:"#"
          // },
          // {
          //   childMenu:[
          //     {id:352,name:"关注量",parentId:101,path:"/numberFollowers"},
          //     {id:353,name:"粉丝量",parentId:101,path:"/fanBase"},
          //     {id:354,name:"访问量",parentId:101,path:"/visits"},
          //   ],
          //   icon:"https://yun-new-admin-1252497858.cos.ap-guangzhou.myqcloud.com/images/c9e178f4-f55d-49c5-8d63-0cdfc6be5961.png",
          //   id:101,
          //   name:"会员社交记录导出",
          //   parentId:0,
          //   path:"#"
          // },
          // {
          //   childMenu:[
          //     {id:358,name:"职称考试纠错记录",parentId:102,path:"/UserErrorCorrection"},
          //     {id:359,name:"药品查询纠错记录",parentId:102,path:"/errorHistory"},
          //   ],
          //   icon:"https://yun-new-admin-1252497858.cos.ap-guangzhou.myqcloud.com/images/c9e178f4-f55d-49c5-8d63-0cdfc6be5961.png",
          //   id:102,
          //   name:"用户纠错管理",
          //   parentId:0,
          //   path:"#"
          // },
          // {
          //   childMenu:[
          //     {id:360,name:"同步练习使用记录",parentId:103,path:"/synchronousPracticeRecords"},
          //     {id:361,name:"模拟考试使用记录",parentId:103,path:"/practiceTestRecords"},
          //     {id:362,name:"历年真题使用记录",parentId:103,path:"/previousExamPapersRecords"},
          //     {id:363,name:"每日一练使用记录",parentId:103,path:"/useDailyPracticeRecord"},
          //     {id:364,name:"错题添加记录",parentId:103,path:"/errorAddRecord"},
          //     {id:365,name:"经验值获得记录",parentId:103,path:"/experienceValueRecord"},
          //     {id:366,name:"笔记添加记录",parentId:103,path:"/addNotesRecord"},
          //     {id:367,name:"考点精选浏览记录",parentId:103,path:"/examPointsLook"},
          //     {id:368,name:"考试信息浏览记录",parentId:103,path:"/examMessageLook"},
          //   ],
          //   icon:"https://yun-new-admin-1252497858.cos.ap-guangzhou.myqcloud.com/images/c9e178f4-f55d-49c5-8d63-0cdfc6be5961.png",
          //   id:103,
          //   name:"职称考试记录导出",
          //   parentId:0,
          //   path:"#"
          // },
        ],
        select:['0'],
        openKeys:[],
        rootSubmenuKeys: ['sub1', 'sub2', 'sub4'],
      }
    },
    async created() {
      // await this.getMenus();
      await this.change_select()
      this.getTableList()
    },
    computed:{
      liveHistory(){
        return this.$store.state.liveMeetHistory
      }
    },
  
    watch:{
      $route(to,from){
        if (to.path==='/'){
          this.select=['0']
        }
      }
    },
    mounted() {
  
    },
    methods:{
      async getTableList(){
        const response =await getfirstMenu()
        if (response.code===0){
          response.data.forEach(item=>{
            if(item.path === " "){
              this.obtaintSecondThreeList(item.id)
            }
          })
        }else {
          message.warn(response.message)
        }
      },
      async obtaintSecondThreeList(id){
         let data =  {
          topMenuId:id,
         }
         const res = await getSecondThreeMenu(data)
         if (res.statuscode === 200){
           this.menus = res.data
           let path = this.$route.path;
           this.menus.forEach(item => {
             item.childMenu.forEach(item2 => { 
               if (item2.path === path) {
                 this.openKeys[0] = item.id
               }
          })
        })
         }else {
           message.warn(res.message)
         }
       },
      async change_select(){
        let path = this.$route.path;
        let select  = []
        select.push(path)
        this.select = select
        let menuData = this.menus;
        // for (const menuDataKey in menuData) {
        //   for (const itemKey in menuData[menuDataKey].childMenu){
        //     let myList = [];
        //     if (menuData[menuDataKey].childMenu[itemKey].path === path) {
        //       myList.push(menuData[menuDataKey].childMenu[itemKey].parentId)
        //       this.openKeys =myList
        //       break;
        //     }
        //   }
        // }
  
  
      },
  
      // async getMenus(){
      //   const response = await manageMenus();
      //   if (response.code === 0){
      //     this.menus = response.data
      //   }
      // },
      onOpenChange(openKeys) {
        const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
        if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
          this.openKeys = openKeys;
        } else {
          this.openKeys = latestOpenKey ? [latestOpenKey] : [];
        }
      },
      menuTitle(title){
        this.$store.dispatch('setManageHeaderTitle',title)
        // 判断是菜单点击进入
        this.liveHistory.isRouter = true
      },
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .logo{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .menuLIst{
    border-radius:5px;width:23px;height: 23px;
    margin-right:8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ::v-deep .ant-menu-inline .ant-menu-submenu-title{
    // padding: 0px 16px 0 0px;
    // padding-right:10px;
    // padding-left:2px
  }
  </style>
  