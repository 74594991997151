<template>
  <div style="margin: -15px -15px;margin-right: -25px;overflow-x: hidden;">
    <a-spin :spinning="spinning">
      <div class="registerMember">
        <!-- 身份选择菜单 -->
        <!-- <div class="registerMember-left">
          <div>
            <a-menu style="width: 100%" v-model="selectId" mode="inline">
              <a-menu-item
                v-for="(item, index) in templateList"
                @click="selectStatus(item.title)"
                :key="item.title"
              >
                {{ item.title }}
              </a-menu-item>
            </a-menu>
          </div>
        </div> -->
        <div class="registerMember-right">
          <div class="registerMember-right-top">
            <!--   顶部搜索  -->
            <div style="display: flex;align-items:center; text-align: center;height: 100%;padding-left: 0px;">
              <div class="registerMember-right-top-input">
                手机号码:
                <a-input
                  v-model="searchForm.number"
                  style="width: 60%"
                  @pressEnter="enterBtn"
                  placeholder="请输入"
                />
              </div>
              <div class="registerMember-right-top-input">
                姓名:
                <a-input
                  v-model="searchForm.name"
                  style="width: 70%"
                  @pressEnter="enterBtn"
                  placeholder="请输入"
                />
              </div>
              <div class="registerMember-right-top-input" style="margin-left:20px">
                状态:
                <a-radio-group
                  style="margin-left: 5px"
                  v-model="searchForm.certStatus"
                  @change="enterBtn"
                >
                  <a-radio-button value=""> 全部 </a-radio-button>
                  <a-radio-button value="CERTIFIED"> 已认证 </a-radio-button>
                  <!-- <a-radio-button value="IN_REVIEW"> 待审核 </a-radio-button> -->
                  <a-radio-button value="UNCERTIFIED"> 未认证 </a-radio-button>
                </a-radio-group>
              </div>
              <div>
                <a-button @click="selectBtn" type="primary" style="margin-left: 40px"
                  >查询</a-button
                >
                <a-button @click="restBtn" style="margin-left: 10px"
                  >重置</a-button
                >
              </div>
            </div>
          </div>
          <div class="registerMember-right-table">
            <!--   列表   -->
            <!-- <div class="registerMember-right-table-top-btn">
              <a-button @click="addBtn" type="primary">新增</a-button>
              <a-popconfirm
                style="margin-left: 10px"
                placement="top"
                ok-text="是"
                cancel-text="否"
                @confirm="resetConfirm"
              >
                <template slot="title">
                  <p>是否重置密码？</p>
                </template>
                <a-button>重置密码</a-button>
              </a-popconfirm>
              <a-button style="margin-left: 10px" @click="amendBtn"
                >修改</a-button
              >
            </div> -->
            <!--   列表   -->
            <div class="registerMember-right-table-top-table">
              <a-table
                :scroll="{ x: 1600 }"
                :pagination="false"
                style="width: 99%"
                :columns="columns"
                :rowKey="(record) => record.id"
                :data-source="tableList"
              >
              <!--                 :row-selection="{
                  selectedRowKeys: selectedRowKeys,
                  onChange: onSelectChange,
                }" -->
                <span slot="hospitalOffices" slot-scope="text, record">
                  <!--      新添加的数据都是带以及科室，拆分字符串显示二级科室     -->
                  <span v-if="reg.test(record.hospitalOffices)">{{
                    record.hospitalOffices.split("-")[1]
                  }}</span>
                  <span v-else>{{ record.hospitalOffices }}</span>
                </span>
                <span slot="createTime" slot-scope="text, record">
                  <span>否</span>
                </span>
                <span slot="isSummit" slot-scope="text, record">
                  <span>{{ text === 1?'已完善':'未完善' }}</span>
                </span>
                <span slot="certStatus" slot-scope="text, record">
                  <div v-if="text === 'AGREED'">
                    <admin_con
                      style="color: #02ac27"
                      class="icon"
                      type="icon-yuandian1"
                    />已认证
                  </div>
                  <div v-if="text === 'PENDING'">
                    <admin_con
                      style="color: #0e77d1"
                      class="icon"
                      type="icon-yuandian1"
                    />待审核
                  </div>
                  <div v-if="text === 'UNCERTIFIED'">
                    <admin_con
                      style="color: #a1a3a2"
                      class="icon"
                      type="icon-yuandian1"
                    />未认证
                  </div>
                  <div v-if="text === 'REJECT'">
                    <admin_con
                      style="color: #a1a3a2"
                      class="icon"
                      type="icon-yuandian1"
                    />已拒绝
                  </div>
                </span>

                <span slot="hospitalName" slot-scope="text, record">
                  <span v-if="record.hospitalName">{{
                    record.hospitalName
                  }}</span>
                  <span v-if="record.school">{{ record.school }}</span>
                  <span v-if="record.workUnit">{{ record.workUnit }}</span>
                </span>
                <span slot="type" slot-scope="text, record">
                  {{ text == 1001?'医生':text == 1002?'护士':text == 1003?'医技':text == 1004?'药师':text == 1005 ? '学生':text == 1007 ?'非医务从业者': text == 1006?'其他医务从业者': text == 0? '身份不明人员' :'' }}
                    <!-- {{ text }} -->
                  <!-- <span>{{ record.isStaff === 1?'内部成员': record.type == 1001?'医生':record.type == 1002?'护士':record.type == 1003?'医技':record.type == 1004?'药师':record.type == 1005 ? '学生':record.type == 1007 ?'非医务从业者': record.type == 1006?'其他医务从业者': record.type == 0? '身份不明人员':''  }}</span> -->
                </span>

                <span slot="operation" slot-scope="text, record">
                  <a @click="sign(record)" style="margin-right:5px">标记</a>
                  <a-popconfirm
                    placement="top"
                    ok-text="是"
                    cancel-text="否"
                    @confirm="resetConfirmTable(record.id)"
                  >
                    <template slot="title">
                      <p>是否重置密码？</p>
                    </template>
                    <a>重置密码</a>
                  </a-popconfirm>
                  <a @click="amendBtnTable(record.id)" style="margin-left: 8px"
                    >修改</a
                  >
                </span>
              </a-table>
                          <!--      分页-->
            <div style="width: 100%; ">
              <div class="pageClass">
                <a-pagination
                  show-quick-jumper
                  :current="pageNo"
                  @change="pageChange"
                  :total="total"
                />
              </div>
            </div>
            </div>
          </div>
        </div>
        <!----------------------------------     对话框     --------------------------------->
        <!--            新增/修改对话框-->
        <ListPackaging></ListPackaging>
        <a-drawer
          :title="drawerTitle"
          placement="right"
          :closable="false"
          :visible="addVisible"
          @close="addClose"
          width="500"
        >
          <div class="addForms">
            <a-form-model
              ref="addRuleForm"
              :model="addForm"
              :rules="rules"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
            >
              <a-form-model-item label="身份" prop="type">
                <a-select
                  show-search
                  option-filter-prop="children"
                  @change="selectNumberType"
                  placeholder="请选择"
                  v-model="addForm.type"
                  style="width: 300px"
                >
                  <a-select-option value="1001"> 医生 </a-select-option>
                  <a-select-option value="1002"> 护士 </a-select-option>
                  <a-select-option value="1003"> 医技 </a-select-option>
                  <a-select-option value="1004"> 药师 </a-select-option>
                  <a-select-option value="1005"> 学生 </a-select-option>
                  <a-select-option value="1006">
                    其他医务从业者
                  </a-select-option>
                  <a-select-option value="1007"> 非医务从业者 </a-select-option>
                  <!-- <a-select-option value="1008"> 内部人员 </a-select-option> -->
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="姓名" prop="name">
                <a-input
                  style="width: 300px"
                  v-model="addForm.name"
                  placeholder="请输入"
                />
              </a-form-model-item>
              <a-form-model-item label="手机号" prop="tel">
                <a-input
                  style="width: 300px"
                  v-model="addForm.tel"
                  placeholder="请输入"
                />
              </a-form-model-item>
              <a-form-model-item label="省份/城市" prop="province">
                <a-cascader
                  style="width: 300px"
                  v-model="addForm.province"
                  :options="hospitaloPtions"
                  placeholder="请选择"
                  @change="provinceChange"
                />
              </a-form-model-item>
              <!-------------------------------1001 ~ 1004---------------------------------->
              <a-form-model-item
                v-if="
                  addForm.type === '1001' ||
                  addForm.type === '1002' ||
                  addForm.type === '1003' ||
                  addForm.type === '1004'
                "
                label="医院"
                prop="hospitalName"
              >
                <a-select
                  style="width: 300px"
                  v-model="addForm.hospitalName"
                  placeholder="请选择"
                  @change="hospitalSeleChange"
                >
                  <a-select-option
                    v-for="item in hospitalNameList"
                    :value="item.name"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item
                v-if="
                  addForm.type === '1001' ||
                  addForm.type === '1002' ||
                  addForm.type === '1003' ||
                  addForm.type === '1004'
                "
                label="科室"
                prop="hospitalOffices"
              >
                <a-cascader
                  style="width: 300px"
                  v-model="addForm.hospitalOffices"
                  :options="hospitalOfficesList"
                  placeholder="请选择"
                  @change="officeChange"
                />
              </a-form-model-item>
              <a-form-model-item
                v-if="
                  addForm.type === '1001' ||
                  addForm.type === '1002' ||
                  addForm.type === '1003' ||
                  addForm.type === '1004'
                "
                label="职称"
                prop="jobTitle"
              >
                <a-select  
                  style="width: 300px"
                  v-model="addForm.jobTitle"
                  placeholder="请选择"
                  @change="jobTitleChange"
                >
                  <a-select-option
                    v-for="item in jobTitleList"
                    :value="item.jobTitle"
                  >
                    {{ item.jobTitle }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <!-------------------------------1005------------------------------------------->
              <a-form-model-item
                v-if="addForm.type === '1005'"
                label="学院"
                prop="school"
              >
                <a-select
                  style="width: 300px"
                  v-model="addForm.school"
                  placeholder="请选择"
                >
                  <a-select-option
                    v-for="item in schoolListData"
                    :value="item.name"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item
                v-if="addForm.type === '1005'"
                label="学历"
                prop="education"
              >
                <a-select
                  style="width: 300px"
                  placeholder="请选择"
                  v-model="addForm.education"
                >
                  <a-select-option
                    v-for="item in educationList"
                    :value="item.education"
                  >
                    {{ item.education }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item
                v-if="addForm.type === '1005'"
                label="专业"
                prop="specialty"
              >
                <a-input
                  style="width: 300px"
                  v-model="addForm.specialty"
                  placeholder="请输入"
                />
              </a-form-model-item>
              <!-------------------------------1006  ~  1007------------------------------------------->
              <a-form-model-item
                v-if="addForm.type === '1006' || addForm.type === '1007'"
                label="单位"
                prop="workUnit"
              >
                <a-input
                  style="width: 300px"
                  v-model="addForm.workUnit"
                  placeholder="请输入"
                />
              </a-form-model-item>
              <a-form-model-item
                v-if="addForm.type === '1006' || addForm.type === '1007'"
                label="职务"
                prop="post"
              >
                <a-input
                  style="width: 300px"
                  v-model="addForm.post"
                  placeholder="请输入"
                />
              </a-form-model-item>

              <!--      新增时共有的        -->
              <a-form-model-item label="性别">
                <a-select
                  style="width: 300px"
                  placeholder="请选择"
                  v-model="addForm.sex"
                >
                  <a-select-option v-for="item in sexList" :value="item.value">
                    {{ item.sex }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="邮箱">
                <a-input
                  style="width: 300px"
                  v-model="addForm.email"
                  placeholder="请输入"
                />
              </a-form-model-item>
              <a-form-model-item label="生日">
                <a-date-picker
                  placeholder="请选择"
                  style="width: 300px"
                  v-model="addForm.birthday"
                />
              </a-form-model-item>
              <a-form-model-item label="认证状态">
                <a-input
                  :disabled="true"
                  style="width: 300px"
                  v-model="addForm.certStatus"
                  placeholder="请输入"
                />
              </a-form-model-item>
              <a-form-model-item label="注册渠道">
                <a-input
                  :disabled="true"
                  style="width: 300px; margin-bottom: 20px"
                  v-model="addForm.registerChannel"
                  placeholder="请输入"
                />
              </a-form-model-item>
            </a-form-model>
          </div>
          <div
            :style="{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
              zIndex: 1,
            }"
          >
            <a-button :style="{ marginRight: '8px' }" @click="addClose">
              取消
            </a-button>
            <a-button type="primary" @click="onSubmit"> 确认 </a-button>
          </div>
        </a-drawer>
      </div>
      <!-- 已认证图片弹窗 -->
      <a-modal
        v-model="modaLshow"
        title="标记"
        @ok="handleOk"
        @cancel="handleCancel"
        width="330px"
      >
        是否完善资料：
        <a-switch
          :checked="isSummit !== 0"
          v-model="is_open"
          checked-children="是"
          un-checked-children="否"
          @click="communityListSwitch(is_open)"
          default-checked
        />
        <br>
        <span style="margin-top:20px;display: inline-block;">是否内部人员：</span> 
        <a-switch
          :checked="isStaff !== 0"
          v-model="isStaff_is_open"
          checked-children="是"
          un-checked-children="否"
          @click="community2ListSwitch(isStaff_is_open)"
          default-checked
        />
        <div style="height: 60px;"></div>
      </a-modal>
    </a-spin>
  </div>
</template>

<script>
import City from "@/assets/city.json";
import {
  getIsStaff,putSummit
} from "@/service/InternalMemberManagement_api";
import {
  getMemberList,
  postMember,
  putMember,
} from "@/service/MedicalConference_y";
import { getHospital } from "@/service/hospital_api";
import { getLoginDepartment } from "@/service/dataCleaned_api";
import { getTitle } from "@/service/dataDictionary_api";
import { getSchool } from "@/service/school_api";
import { delAdminList, restPassWorld } from "@/service/recruitment";
import moment from "moment";
import ListPackaging from "@/views/content/case/ListPackaging";
import { admin_icon } from "@/utils/myIcon_f";
import { educationList, sexDate } from "@/utils/dateReturn";
export default {
  name: "registerMember",
  components: {
    ListPackaging,
    admin_con: admin_icon,
  },
  data() {
    return {
      modaLshow:false,
      // cmovies:false,
      City: City,
      PCHList: [],
      selectId: ["全部会员"],
      //选择框
      templateList: [
        {
          title: "全部会员",
          id: 1,
        },
        {
          title: "医护人员",
          id: 2,
        },
        {
          title: "医学生",
          id: 3,
        },
        {
          title: "其他",
          id: 4,
        },
      ],
      //顶部搜索
      searchForm: {
        number: "",
        name: "",
        certStatus: "",
      },
      columns: [
        {
          title: "身份",
          dataIndex: "type",
          // width: "14%",
          key: "type",
          align: "center",
          scopedSlots: { customRender: "type" },
        },
        {
          title: "姓名",
          dataIndex: "name",
          // width: "10%",
          key: "name",
          align: "center",
        },
        {
          title: "手机号码",
          dataIndex: "tel",
          key: "tel",
          align: "center",
          // width: "15%",
        },
        {
          title: "医院/单位",
          dataIndex: "hospitalName",
          // width: "20%",
          align: "hospitalName",
          key: "tel2",
          scopedSlots: { customRender: "hospitalName" },
        },
        {
          title: "科室",
          dataIndex: "hospitalOffices",
          key: "hospitalOffices",
          ellipsis: true,
          // width: "10%",
          align: "center",
          scopedSlots: { customRender: "hospitalOffices" },
        },
        {
          title: "职称",
          dataIndex: "jobTitle",
          key: "jobTitle",
          ellipsis: true,
          // width: "10%",
          align: "center",
        },
        // {
        //   title: "职务",
        //   dataIndex: "post",
        //   key: "post",
        //   ellipsis: true,
        //   width: "20%",
        //   align: "center",
        // },
        {
            title: "资料状态",
            dataIndex: "isSummit",
            key: "isSummit",
            align: "center",
            scopedSlots: { customRender: "isSummit" },
        },
        {
          title: "认证状态",
          dataIndex: "certifyRecordStatus",
          key: "certifyRecordStatus",
          ellipsis: true,
          // width: "12%",
          align: "center",
          scopedSlots: { customRender: "certStatus" },
        },
        {
          title: "平台专家",
          dataIndex: "createTime",
          key: "createTime",
          // width: "10%",
          align: "center",
          scopedSlots: { customRender: "createTime" },
        },
        // {
        //   title: "学历",
        //   dataIndex: "education",
        //   key: "education",
        //   width: "12%",
        //   align: "center",
        // },
        {
            title: "注册渠道",
            dataIndex: "registerChannel",
            key: "registerChannel",
            // width: "20%",
            align: "center",
            // scopedSlots: { customRender: "registerSlotChannel" },
          },
        {
          title: "注册时间",
          dataIndex: "createdTime",
          key: "createdTime",
          // width: "20%",
          align: "center",
        },
        {
          title: "操作",
          dataIndex: "operation",
          key: "address 4",
          fixed: "right",
          width: 200,
          // align: "center",
          scopedSlots: { customRender: "operation" },
        },
      ],
      tableList: [],
      //总数
      total: 2,
      pageNo: 1,
      spinning: false,
      addVisible: false,
      selectedRowKeys: [],
      user_id: [],
      labelCol: { span: 5 },
      wrapperCol: { span: 14 },
      addForm: {
        type: "1001",
        name: "",
        tel: "",
        province: undefined,
        city: "",
        hospitalName: undefined,
        hospitalOffices: undefined,
        jobTitle: undefined,
        school: undefined,
        education: undefined,
        specialty: "",
        workUnit: "",
        post: "",
        sex: undefined,
        email: "",
        birthday: "",
        certStatus: "未认证",
        registerChannel: "后台添加",
      },
      rules: {},
      //1001-1004
      rules1: {
        type: [{ required: true, message: "请选择", trigger: "blur" }],
        name: [{ required: true, message: "请输入", trigger: "change" }],
        tel: [{ required: true, message: "请输入", trigger: "change" }],
        province: [{ required: true, message: "请选择", trigger: "change" }],
        hospitalName: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        hospitalOffices: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        jobTitle: [{ required: true, message: "请选择", trigger: "change" }],
      },
      //1005
      rules2: {
        type: [{ required: true, message: "请选择", trigger: "blur" }],
        name: [{ required: true, message: "请输入", trigger: "change" }],
        tel: [{ required: true, message: "请输入", trigger: "change" }],
        province: [{ required: true, message: "请选择", trigger: "change" }],
        school: [{ required: true, message: "请选择", trigger: "change" }],
        education: [{ required: true, message: "请选择", trigger: "change" }],
        specialty: [{ required: true, message: "请输入", trigger: "change" }],
      },
      //1006-1007
      rules3: {
        type: [{ required: true, message: "请选择", trigger: "blur" }],
        name: [{ required: true, message: "请输入", trigger: "change" }],
        tel: [{ required: true, message: "请输入", trigger: "change" }],
        province: [{ required: true, message: "请选择", trigger: "change" }],
        workUnit: [{ required: true, message: "请输入", trigger: "change" }],
        post: [{ required: true, message: "请输入", trigger: "change" }],
      },
      hospitaloPtions: [],
      schoolPtions: [],
      options: [
        {
          value: "zhejiang",
          label: "Zhejiang",
          isLeaf: false,
        },
        {
          value: "jiangsu",
          label: "Jiangsu",
          isLeaf: false,
        },
      ],
      hospitalNameList: [],
      hospitalNameList_pageNo: 1,
      hospitalOfficesList: [],
      jobTitleList: [],
      schoolListData: [],
      educationList: educationList(),
      sexList: sexDate(),
      // educationList:[
      //   {
      //     id:'1',
      //     education:'初中'
      //   },
      //   {
      //     id:'2',
      //     education:'高中'
      //   },
      //   {
      //     id:'3',
      //     education:'专科'
      //   },
      //   {
      //     id:'4',
      //     education:'本科'
      //   },
      //   {
      //     id:'5',
      //     education:'研究生'
      //   },
      // ],
      // sexList:[
      //   {
      //     id:1,
      //     sex:'男',
      //     value:1,
      //   },{
      //     id:2,
      //     sex:'女',
      //     value:2,
      //   },
      //   {
      //     id:3,
      //     sex:'未知',
      //     value:0,
      //   },
      // ],
      // certStatusList:[
      //   {
      //     id:1,
      //     certStatus:'未认证',
      //     value:0,
      //   },{
      //     id:2,
      //     certStatus:'已认证',
      //     value:1,
      //   },
      // ],
      drawerTitle: "",
      reg: RegExp(/-/),
      isSummit:0,
      isStaff:0,
      isStaff_is_open:false,
      is_open:false,
      memberId:"",
    };
  },
  created() {
    this.getList();
    this.getHospitalOfficesList();
    this.getJobTitleList(this.addForm.type, 1);
    this.$store.dispatch("setManageHeaderTitle", "注册会员管理");
  },
  watch: {
    //监听对话框处于关闭状态下，将选择框置空
    listBoxIsShow(newVal) {
      if (!newVal) {
        //选择的列表的id
        this.user_id = [];
        this.selectedRowKeys = [];
        this.getList();
      }
    },
  },
  computed: {
    //  vuex-名单对话框数据
    listBox() {
      return this.$store.state.listDialogBox;
    },
    listBoxIsShow() {
      return this.$store.state.listDialogBox.isShow;
    },
  },
  mounted() {
    this.rules = this.rules1;
    this.getPCH();
  },
  methods: {
    async communityListSwitch(isOpen) {
        const response = await putSummit(this.memberId,this.isStaff,isOpen === false?0:1);
        if (response.code === 0) {
          this.$message.success("操作成功");
          this.getList()
        //   this.modaLshow = false
        } else {
          this.$message.warning(response.message);
        }
      },
    //   是否内部人员
      async community2ListSwitch(isOpen) {
        const response = await putSummit(this.memberId,isOpen === false?0:1,this.isSummit);
        if (response.code === 0) {
          this.$message.success("操作成功");
          // this.modaLshow = false
          this.getList()
        } else {
          this.$message.warning(response.message);
        }
      },
    handleOk(){
      this.modaLshow = false
    },
    handleCancel(){
      this.modaLshow = false
    },
    sign(record){
      this.modaLshow = true
      this.isSummit = record.isSummit
      this.is_open = record.isSummit === 1?true:false
      this.memberId = record.id
      this.isStaff = record.isStaff
      this.isStaff_is_open = record.isStaff === 1?true:false
      // console.log(this.memberId)
    },
    async getList() {
      this.spinning = true;
      let form = this.searchForm; 
      let data = {
        name: form.name,
        tel: form.number,
        certStatus: form.certStatus,
        pageNo: this.pageNo,
        type: this.selectId[0],
      };
      const response = await getMemberList(data);
      if (response.code === 0) {
        this.tableList = response.data;
        this.total = response.count;
      } else {
        this.$message.warning(response.message);
      }
      this.spinning = false;
    },
    //获取省份城市医院
    getPCH() {
      let province = [];
      let hospitalName = [];
      this.City.forEach((item, index) => {
        let city = [];
        province.push({
          value: item.name,
          label: item.name,
          children: city,
        });
        item.cityList.forEach((list) => {
          city.push({
            value: list.name,
            label: list.name,
            loading: false,
          });
        });
        province[index].children = city;
      });
      this.hospitaloPtions = province;
      this.schoolPtions = province;
    },
    selectStatus(value) {
      this.pageNo = 1;
      this.selectId[0] = value;
      this.getList();
    },
    enterBtn() {
      this.getList();
    },
    selectBtn() {
      this.getList();
    },
    restBtn() {
      let form = this.searchForm;
      form.number = "";
      form.name = "";
      form.certStatus = ''
      this.pageNo = 1
      this.getList();
    },
    addBtn() {
      this.drawerTitle = "新增";
      this.addVisible = true;
    },
    resetConfirmTable(id) {
      let userId = [id];
      this.restPass(userId);
    },
    resetConfirm() {
      const userId = this.user_id;
      if (userId !== "" && userId !== null && userId !== undefined) {
        this.restPass(userId);
      } else {
        this.$message.warning("请选择需要重置密码的列表~");
      }
    },
    async restPass(userId) {
      const response = await restPassWorld(userId);
      if (response.code === 0) {
        this.$message.success("重置密码成功，密码重置后为 111111");
        //清空
        this.selectedRowKeys = [];
        this.user_id = [];
        //刷新列表
        await this.getList();
      } else {
        this.$message.warning(response.message);
      }
    },
    // async delConfirm() {
    //   const userIds = this.user_id
    //   if (userIds !== '' && userIds !== null && userIds !== undefined) {
    //     const response = await delAdminList(userIds)
    //     if (response.code === 0) {
    //       this.$message.success("删除成功~")
    //       await this.getList()
    //     } else {
    //       this.$message.error(response.message)
    //     }
    //     //清空
    //     this.selectedRowKeys = []
    //     this.user_id = []
    //   } else {
    //     this.$message.warning("请选择需要删除的列表！")
    //   }
    // },
    lookBtn() {},
    amendBtn() {
      let listId = this.user_id;
      if (listId.length === 1) {
        this.drawerTitle = "修改";
        //获取单个列表内容
        // this.getListOne(listId[0])
        // this.addVisible = true
        this.listBox.memberId = listId[0];
        //打开对话框 -- ListPackaging
        this.listBox.isShow = true;
      } else {
        this.$message.warning("请选择想要修改的一个列表~");
      }
    },
    amendBtnTable(id) {
      this.listBox.memberId = id;
      // console.log(this.listBox);
      //打开对话框 -- ListPackaging
      this.listBox.isShow = true;
    },
    // async getListOne(listId) {
    //   let data = {
    //     id:listId,
    //   }
    //   const response = await getMemberList(data)
    //   if(response.code === 0){
    //   //  赋值
    //   //  this.addForm = response.data[0]
    //   //   this.addForm.province = [response.data[0].province,response.data[0].city]
    //   //   if(this.addForm.hospitalOffices){
    //   //     this.addForm.hospitalOffices = response.data[0].hospitalOffices.split('-')
    //   //   }
    //
    //     //  赋值
    //     this.listBox.addForm = response.data[0]
    //     this.listBox.addForm.province = [response.data[0].province,response.data[0].city]
    //     if(this.listBox.addForm.hospitalOffices){
    //       this.listBox.addForm.hospitalOffices = response.data[0].hospitalOffices.split('-')
    //     }
    //   }else {
    //     this.$message.warning(response.message)
    //   }
    // },
    pageChange(pageNo) {
      this.pageNo = pageNo;
      this.getList();
    },
    selectNumberType() {
      this.$set(this.addForm, "jobTitle", undefined);
      //其他医务从业者--医务从业者不需要选择省份城市
      if (this.addForm.type !== "1006" || this.addForm.type !== "1007") {
        this.getJobTitleList(this.addForm.type, 1);
      }
      //选择身份时切换rules校验
      switch (this.addForm.type) {
        case "1001": {
          this.rules = this.rules1;
          break;
        }
        case "1002": {
          this.rules = this.rules1;
          break;
        }
        case "1003": {
          this.rules = this.rules1;
          break;
        }
        case "1004": {
          this.rules = this.rules1;
          break;
        }
        case "1005": {
          this.rules = this.rules2;
          break;
        }
        case "1006": {
          this.rules = this.rules3;
          break;
        }
        case "1007": {
          this.rules = this.rules3;
          break;
        }
        default: {
          break;
        }
      }
    },

    onSelectChange(selectedRowKeys) {
      //选择的列表的id
      this.user_id = selectedRowKeys;
      this.selectedRowKeys = selectedRowKeys;
    },
    provinceChange(value) {
      if (
        this.addForm.type === "1001" ||
        this.addForm.type === "1002" ||
        this.addForm.type === "1003" ||
        this.addForm.type === "1004"
      ) {
        let data = {
          province: value[0],
          city: value[1],
          pagenum: this.hospitalNameList_pageNo,
        };
        this.getHosNameList(data);
      } else if (this.addForm.type === "1005") {
        let data = {
          province: value[0],
          city: value[1],
        };
        this.getschoolList(data);
      }
      // else if(this.addForm.type === '1006' ||
      //     this.addForm.type === '1007'){
      //
      // }
    },
    //获取职称列表
    async getJobTitleList(identify, pageNum, title) {
      //type:identify,pageNum,title
      const response = await getTitle(identify, pageNum, title);
      if (response.code === 0) {
        this.jobTitleList = response.data.row;
      }
    },
    //获取医院列表
    async getHosNameList(data) {
      const response = await getHospital(data);
      if (response.code === 0) {
        this.hospitalNameList = response.data.rows;
      }
    },
    //获取学院列表
    async getschoolList(data) {
      const response = await getSchool(data);
      if (response.code === 0) {
        this.schoolListData = response.data.rows;
      }
    },
    //获取科室列表
    async getHospitalOfficesList() {
      const response = await getLoginDepartment();
      if (response.code === 0) {
        let oneOff = [];
        let list = response.data.wySy;
        list.forEach((item, index) => {
          let twoOff = [];
          oneOff.push({
            value: item.title,
            label: item.title,
            children: twoOff,
          });
          item.zoffices.forEach((lists) => {
            twoOff.push({
              value: lists.title,
              label: lists.title,
            });
          });
          oneOff[index].children = twoOff;
        });
        this.hospitalOfficesList = oneOff;
      }
    },
    jobTitleChange(value) {
      console.log(value);
    },
    // schoolChange(value) {
    //   let data = {
    //     province:value[0],
    //     city:value[1]
    //   }
    //   this.getschoolList(data)
    // },
    officeChange(value) {
      console.log(value);
    },
    hospitalSeleChange() {},
    addClose() {
      this.addVisible = false;
      //清空
      this.selectedRowKeys = [];
      this.user_id = [];
      this.$refs.addRuleForm.resetFields();
      let form = this.addForm;
      form.birthday = undefined;
      form.sex = undefined;
      form.email = "";
      form.certStatus = "未认证";
      form.registerChannel = "后台添加";
    },
    // 提交新增的表单
    onSubmit() {
      this.$refs.addRuleForm.validate((valid) => {
        if (valid) {
          let form = this.addForm;
          let data = {};
          if (this.drawerTitle === "新增") {
            switch (form.type) {
              case "1001": {
                data = {
                  type: form.type,
                  name: form.name,
                  tel: form.tel,
                  hospitalName: form.hospitalName,
                  hospitalOffices:
                    form.hospitalOffices[0] + "-" + form.hospitalOffices[1],
                  jobTitle: form.jobTitle,

                  province: form.province[0],
                  city: form.province[1],

                  sex: form.sex,
                  email: form.email,
                  birthday: form.birthday
                    ? moment(form.birthday).format("YYYY-MM-DD")
                    : "",
                  certStatus: form.certStatus,
                  registerChannel: form.registerChannel,
                  nvqUrl: "",
                };
                break;
              }
              case "1002": {
                data = {
                  type: form.type,
                  name: form.name,
                  tel: form.tel,
                  hospitalName: form.hospitalName,
                  hospitalOffices:
                    form.hospitalOffices[0] + "-" + form.hospitalOffices[1],
                  jobTitle: form.jobTitle,

                  province: form.province[0],
                  city: form.province[1],

                  sex: form.sex,
                  email: form.email,
                  birthday: form.birthday
                    ? moment(form.birthday).format("YYYY-MM-DD")
                    : "",
                  certStatus: form.certStatus,
                  registerChannel: form.registerChannel,
                  nvqUrl: "",
                };
                break;
              }
              case "1003": {
                data = {
                  type: form.type,
                  name: form.name,
                  tel: form.tel,
                  hospitalName: form.hospitalName,
                  hospitalOffices:
                    form.hospitalOffices[0] + "-" + form.hospitalOffices[1],
                  jobTitle: form.jobTitle,

                  province: form.province[0],
                  city: form.province[1],

                  sex: form.sex,
                  email: form.email,
                  birthday: form.birthday
                    ? moment(form.birthday).format("YYYY-MM-DD")
                    : "",
                  certStatus: form.certStatus,
                  registerChannel: form.registerChannel,
                  nvqUrl: "",
                };
                break;
              }
              case "1004": {
                data = {
                  type: form.type,
                  name: form.name,
                  tel: form.tel,
                  hospitalName: form.hospitalName,
                  hospitalOffices:
                    form.hospitalOffices[0] + "-" + form.hospitalOffices[1],
                  jobTitle: form.jobTitle,

                  province: form.province[0],
                  city: form.province[1],

                  sex: form.sex,
                  email: form.email,
                  birthday: form.birthday
                    ? moment(form.birthday).format("YYYY-MM-DD")
                    : "",
                  certStatus: form.certStatus,
                  registerChannel: form.registerChannel,
                  nvqUrl: "",
                };
                break;
              }
              case "1005": {
                data = {
                  type: form.type,
                  name: form.name,
                  tel: form.tel,
                  school: form.school,
                  education: form.education,
                  specialty: form.specialty,

                  province: form.province[0],
                  city: form.province[1],

                  sex: form.sex,
                  email: form.email,
                  birthday: form.birthday
                    ? moment(form.birthday).format("YYYY-MM-DD")
                    : "",
                  certStatus: form.certStatus,
                  registerChannel: form.registerChannel,
                  nvqUrl: "",
                };
                break;
              }
              case "1006": {
                data = {
                  type: form.type,
                  name: form.name,
                  tel: form.tel,
                  workUnit: form.workUnit,
                  post: form.post,

                  province: form.province[0],
                  city: form.province[1],

                  sex: form.sex,
                  email: form.email,
                  birthday: form.birthday
                    ? moment(form.birthday).format("YYYY-MM-DD")
                    : "",
                  certStatus: form.certStatus,
                  registerChannel: form.registerChannel,
                  nvqUrl: "",
                };
                break;
              }
              case "1007": {
                data = {
                  type: form.type,
                  name: form.name,
                  tel: form.tel,
                  workUnit: form.workUnit,
                  post: form.post,

                  province: form.province[0],
                  city: form.province[1],

                  sex: form.sex,
                  email: form.email,
                  birthday: form.birthday
                    ? moment(form.birthday).format("YYYY-MM-DD")
                    : "",
                  certStatus: form.certStatus,
                  registerChannel: form.registerChannel,
                  nvqUrl: "",
                };
                break;
              }
              default: {
                break;
              }
            }
            //新增
            this.postList(data);
          }
          this.addVisible = false;
          //清空
          this.selectedRowKeys = [];
          this.user_id = [];
          this.$refs.addRuleForm.resetFields();

          form.birthday = undefined;
          form.sex = undefined;
          form.email = "";
          form.certStatus = "未认证";
          form.registerChannel = "后台添加";
        } else {
          return false;
        }
      });
    },
    //  新增
    async postList(data) {
      const response = await postMember(data);
      if (response.code === 0) {
        this.$message.success("新增成功~");
        //  刷新列表
        await this.getList();
      } else {
        this.$message.warning(response.message);
      }
    },
    //  修改
    async putList(id, data) {
      const response = await putMember(id, data);
      if (response.code === 0) {
        this.$message.success("修改成功~");
        //  刷新列表
        await this.getList();
      } else {
        this.$message.warning(response.message);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.registerMember {
  width: 100%;
  display: flex;
  .registerMember-left {
    width: 10%;
  }
  .registerMember-right {
    width: 100%;
    // border-left: 15px solid #f5f5f5;
    min-height: 800px;
    .registerMember-right-top {
      // padding-top: 15px;
      width: 100%;
      height: 70px;
      border-bottom: 10px solid #f5f5f5;
      .registerMember-right-top-input {
        // min-width: 25%;
        height: 50px;
        line-height: 50px;
      }
    }
    .registerMember-right-table {
      width: 100%;
      .registerMember-right-table-top-btn {
        width: 100%;
        height: 50px;
        margin-left: 20px;
        margin-top: 15px;
      }
      .registerMember-right-table-top-table {
        width: 100%;
        margin:20px 15px 20px 15px;
        box-sizing: border-box;
      }
    }
    .pageClass {
      width: 100%;
      text-align: right;
      float: right;
      margin-top: 15px;
      box-sizing: border-box;
      padding-right:50px
    }
  }
}
.icon {
  font-size: 8px;
  margin-right: 5px;
}
::v-deep .ant-table-thead > tr > th {
    font-weight: bold;
  }
</style>
