<template>
  <!--  文章管理-->
  <div class="activeManager">

    <a-spin :spinning="spinning">
      <div class="search">
        <div>
          年份: &nbsp;<a-date-picker
            style="width: 13%;margin-right: 15px"
            mode="year"
            :disabled-date="disabledDate"
            placeholder="请选择"
            format='YYYY'
            v-model="yearQuarter" 
            :open='yearShowOne'
            @openChange="openChangeOne"
            @panelChange="panelChangeOne"/>
          作者: &nbsp;<a-input v-model="searchName" placeholder="请输入" size="default"
                             style="width: 180px" @pressEnter="()=>this.searchOk()"/>&nbsp;&nbsp;&nbsp;
          标题: &nbsp;<a-input v-model="searchTitle" placeholder="请输入" size="default"
                             style="width: 180px" @pressEnter="()=>this.searchOk()"/>&nbsp;&nbsp;&nbsp;
<!--          栏目: &nbsp;<a-select v-model="searchType" style="width: 120px" @change="()=>this.searchOk()">-->
<!--          <a-select-option v-for="(codes,index) in codeList" :key="index" :value="codes.title">-->
<!--            {{codes.title}}-->
<!--          </a-select-option>-->
<!--        </a-select>&nbsp;&nbsp;&nbsp;-->
          <a-button style="margin-left: 10px" type="primary" @click="searchOk">查询</a-button>
          <a-button style="margin-left: 10px" @click="searchReset">重置</a-button>
        </div>
      </div>

      <div style="display: flex;justify-content: space-between;">
        <div class="menu">
          <a-menu
              v-model="menu"
              mode="inline"
              style="width: 100%;"
          >
            <a-menu-item
                v-for="item in typeInfo"
                :key="item.code"
                @click="leftChange(item.code)"
            >
              {{ item.title }}
            </a-menu-item>
          </a-menu>
        </div>
        <div class="center">
          <a-button type="primary" @click="addBtn">新增</a-button>
          <a-table :columns="columns"
                   :data-source="tableInfo"
                   :scroll="{ x: 1500}"
                   :pagination="false" rowKey="id" style="margin-bottom: 10px;min-height: 420px;margin-top: 10px"

          >
            <!--        序号-->
            <template slot="index" slot-scope="text,row,index">
              {{index + ((pagination.current - 1) * pagination.pageSize) + 1}}
            </template>
            <span slot="titles" slot-scope="text,item">
              <a :href="item.url" target="_blank">{{ text }}</a>
            </span>
            <span slot="expert" slot-scope="text,item">
              <span  v-if="text">{{text}}</span>
              <span v-else> </span>
            </span>
            <span slot="sort" slot-scope="text,item">
              <a style="margin-right: 10px" @click="move('up',item.id)">上移</a>
              <a @click="move('down',item.id)">下移</a>
            </span>

            <span slot="operate" slot-scope="text,item">
              <a @click="promotions(item)" style="margin-left: 10px">推广</a>
              <a style="margin-left: 10px" @click="editBtn(item)">修改</a>
              <a-popconfirm
                  style="margin-left: 10px"
                  title="确定删除?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="delTable(item.id)"
              >
                <a :disabled="item.state">删除</a>
            </a-popconfirm>
            </span>
          </a-table>
          <div style="display: flex;justify-content: space-between;right: 62px;bottom: 3.5rem;z-index: 3">
                    <span style="font-size: 17px;color: #929292">共 {{ pagination.total }} 条记录 第 {{
                        pagination.current
                      }} / {{ Math.ceil(pagination.total / pagination.pageSize) }} 页</span>
            <a-pagination v-model="pagination.current"
                          :total="pagination.total"
                          showQuickJumper
                          showSizeChanger
                          @showSizeChange="showSizeChange"
                          @change="pageChange"/>
          </div>

        </div>
      </div>
<!-------------------------------弹框----------------------------------------->
      <a-drawer
          :title="addTitle"
          :visible="addShow"
          width="600"
          @close="()=>{this.addShow = false;}"
      >
        <a-form-model
            ref="addItem"
            :label-col="labelCol"
            :model="addForm"
            :rules="addOrRest_rules"
            :wrapper-col="wrapperCol">
          <a-form-model-item prop="templateCode" label="选择样式">
            <a-select v-model="addForm.templateCode" @change="styleType">
              <a-select-option value="T_BOTTOM_1">
                样式一
              </a-select-option>
              <a-select-option value="T_BOTTOM_3">
                样式二
              </a-select-option>
              <a-select-option value="T_RIGHT_1">
                样式三
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <img :src="styletype==='T_BOTTOM_1'? wenStyle1:styletype==='T_BOTTOM_3'? wenStyle2 :styletype==='T_RIGHT_1'? wenStyle3:''"
                 style="width: 70%;margin-left: 25%">
          </a-form-model-item>
          <a-form-model-item :prop="styletype==='T_BOTTOM_3' ? 'imageAll' : 'cover'" label="图片">
            <div v-if="styletype === 'T_BOTTOM_3'">
              <div class="uploadImg" >
                <input
                    multiple
                    id="up_img"
                    style="width: 100%;height:100%;opacity: 0;"
                    accept="image/*"
                    type="file"
                    @change="uploadImg($event,'all')"
                    ref="uploadImg_image" />
              </div>
              <div class="upload_gutter" v-for="img in imageList">
                <img
                    style="width: 105px;height:82px"
                    :src="img"
                >
              </div>
            </div>
            <div v-else>
              <div class="uploadImg" >
                <input
                    id="up_img"
                    style="width: 100%;height:100%;opacity: 0;"
                    accept="image/*"
                    type="file"
                    @change="uploadImg"
                    ref="uploadImg_image" />
              </div>
              <div style="width: 235px;height: 134px"  class="upload_gutter">
                <img
                    v-if="addForm.cover[0]"
                    alt=""
                    style="width: 230px;height:129px"
                    :src="addForm.cover[0]"
                />
              </div>
            </div>
          </a-form-model-item>
          <a-form-model-item  :colon="false" label=" ">
            <div v-if="styletype==='T_BOTTOM_3'" style="color: #a1a1a1">注：建议尺寸330*260px，宽度不能超过330，大小不超过1M</div>
            <div v-else style="color: #a1a1a1">注：建议尺寸1920*1080，宽度不能超过1920，大小不超过2M</div>
          </a-form-model-item>
          <a-form-model-item prop="title" label="标题">
            <a-input placeholder="请输入" v-model="addForm.title"/>
          </a-form-model-item>
          <a-form-model-item label="副标题">
            <a-input placeholder="请输入" v-model="addForm.intro"/>
          </a-form-model-item>
          <a-form-model-item prop="url" label="跳转链接">
            <a-input placeholder="请输入" v-model="addForm.url"/>
          </a-form-model-item>
          <a-form-model-item label="作者" prop="expertId">
            <a-select
                @popupScroll="popupScroll_specialist"
                @search="select_specialist"
                v-model="addForm.expertId"
                show-search
                option-filter-prop="children"
                style="width: 370px"
                placeholder="请选择"
            >
              <a-select-option v-for="(special,inds) in specialistList" :key="inds" :value="special.id">
                {{ special.name }} - {{ special.hospital }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="columnCode" label="所属栏目">
            <a-select placeholder="请选择" v-model="addForm.columnCode">
              <a-select-option v-for="(lists,ind) in codeList" :key="ind" :value="lists.code">
                {{lists.title}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="type" label="分类">
            <a-select  placeholder="请选择" v-model="addForm.type">
              <a-select-option value="ARTICLE_SINGLE">
                文章
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>

        <div
            :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
        >
          <a-button style="marginRight: 8px" @click="()=>{this.addShow = false;this.addForm={}}">
            取消
          </a-button>
          <a-button type="primary" @click="addType=== 'add'?addOk():editOk()">
            确认
          </a-button>
        </div>
      </a-drawer>
      <!--   推广 -->
      <a-drawer
          title="推广"
          :width="500"
          :visible="promotionsVisible"
          :body-style="{ paddingBottom: '80px' }"
          @close="promotionsClose"
      >
        <a-spin :spinning="promotionSpin">
          <div style="margin:20px">
            <a-table :pagination="false" :row-key="record => record.id" :columns="uniAppColumns" :data-source="uniAppData" bordered>
              <span slot="is_open" slot-scope="text, record">
                 <a-switch v-model="record.is_open" checked-children="是" un-checked-children="否" @click="minaRecommend(record)" default-checked />
               </span>
            </a-table>
          </div>
          <div style="margin:20px">
            <a-table :pagination="false" :columns="appColumns" :data-source="appData" :row-key="record => record.code" bordered>
          <span slot="is_open" slot-scope="text, record">
            <a-switch v-if="record.title == '社区首页'" v-model="record.is_open" checked-children="是" un-checked-children="否" @click="communityListSwitch(record.is_open,record)" default-checked />
            <a-switch v-else v-model="record.is_open" checked-children="是" un-checked-children="否" @click="AppListSwitch(record.is_open,record)" default-checked />
          </span>
            </a-table>
          </div>
        </a-spin>
        <div
            :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
        >
          <a-button :style="{ marginRight: '8px' }" @click="promotionsClose">
            关闭
          </a-button>
        </div>
      </a-drawer>

    </a-spin>
  </div>
</template>

<script>
import {update} from "@/utils/update";
import {
  del_active_list, delAppPromotion,
  get_active_list,
  getColumnVodsList,
  get_experts_id, getAppPromotion, getcommunityPromotion,
  move_active_list, post_active_list, postAppPromotion, postcommunityPromotion, put_active_list
} from "@/service/MedicalConference_y";
import {postminaRecommend,getminaRecommend,delminaRecommend} from "@/service/Recruiting";
import moment from "moment";

export default {
  name: "activeManager",
  data() {
    let validateRange = (rule, value, callback) => { // 自定义校验规则-类型
      if (this.imageList.length === 0) {
        callback(new Error('请至少选择一张图片'));
      }else {
        callback();
      }
    };
    return {
      uniAppColumns:[
        {
          title: '小程序',
          key: 'title',
          dataIndex: 'title',
        },
        {
          title: '状态',
          dataIndex: 'is_open',
          key: 'is_open',
          scopedSlots: { customRender: 'is_open' },
        },
      ],
      uniAppData:[
        {
          id:1,
          title:'推荐页（首页）',
          is_open:false
        }
      ],
      yearQuarter: null,
      yearShowOne: false,
      spinning: false,
      addShow: false,
      editShow: false,
      addTitle: '',
      addType: '',
      menu: [],
      labelCol: {span: 4},
      wrapperCol: {span: 16},
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      searchName: '',
      searchTitle: '',
      searchType: '',
      imageList: [],
      styletype: 'T_BOTTOM_1',
      columns: [
        {
          title: '序号',
          fixed: 'left',
          width: 70,
          align: 'center',
          scopedSlots: {customRender: 'index'}
        },
        {
          title: '标题',
          dataIndex: 'title',
          key: 'title',
          width: '25%',
          scopedSlots: {customRender: 'titles'},
        },
        {
          title: '作者',
          dataIndex: 'expert',
          key: 'expert',
          width:'20%',
          scopedSlots: {customRender: 'expert'},
        },
        {
          title: '排序',
          width:'10%',
          align: 'center',
          scopedSlots: {customRender: 'sort'},
        },
        {
          title: '创建时间',
          // width:'15%',
          align: 'center',
          dataIndex: 'createdTime'
        },
        {
          title: '操作',
          width: 150,
          fixed:'right',
          scopedSlots: {customRender: 'operate'},
        },],
      tableInfo: [],
      codeList: [],
      typeInfo: [
        {
          title: '全部'
        },
        {
          title: '文章'
        }
      ],
      wenStyle1: require('/src/assets/wen style1.png'),
      wenStyle2: require('/src/assets/wen style2.png'),
      wenStyle3: require('/src/assets/wen style3.png'),


      data:[],
      addForm: {
        templateCode:'T_BOTTOM_1',
        cover:[''],
        title:'',
        intro:'',
        url:'',
        expertId:undefined,
        columnCode:undefined,
        type:'ARTICLE_SINGLE',
      },
      addOrRest_rules:{
        imageAll: [{ validator:validateRange,required: true, trigger: 'blur' }],
        templateCode: [{ required: true, message: '请选择样式', trigger: 'blur' }],
        cover: [{ required: true, message: '请上传图片', trigger: 'blur' }],
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        url: [{ required: true, message: '请输入跳转链接', trigger: 'blur' }],
        columnCode: [{ required: true, message: '请选择所属栏目', trigger: 'blur' }],
        type: [{ required: true, message: '请选择分类', trigger: 'blur' }],
        expertId: [{required: true, message: '请选择作者', trigger: 'blur'}]
      },
      specialistList:[],
      specialist_page_no:1,
      specialist_title:'',

      promotionsVisible:false,
      appColumns:[
        {
          title: 'APP',
          key: 'title',
          dataIndex: 'title',
        },
        {
          title: '状态',
          dataIndex: 'is_open',
          key: 'is_open',
          scopedSlots: { customRender: 'is_open' },
        },
      ],
      appData:[],
      columnListApp:[],
      promotionId:null,
      subContentType:null,
      promotionSpin:false,
      communityColumns:[
        {
          title: '社区',
          key: 'title',
          dataIndex: 'title',
        },
        {
          title: '状态',
          dataIndex: 'is_open',
          key: 'is_open',
          scopedSlots: { customRender: 'is_open' },
        },
      ],
      communityData:[
        {
          id:1,
          title:'首页',
          is_open:false
        }
      ],
      communityCode:'',
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle','文章管理')
    this.getCodeList()
  },
  mounted() {
  },
  methods: {
    async minaRecommend(record){
      if(record.is_open === true){
        const data = {
          contentId:this.promotionId,
          contentType:'ARTICLE',
          subContentType:this.subContentType,
        }
        const response = await postminaRecommend(data)
        if(response.code === 0){
          this.$message.success("操作成功！")
        }else {
          this.$message.warning(response.message)
        }
      }else{
        const data = {
          contentId:this.promotionId,
          contentType:'ARTICLE',
          subContentType:this.subContentType,
        }
        const response = await delminaRecommend(data)
        if(response.code === 0){
          this.$message.success("操作成功！")
        }else {
          this.$message.warning(response.message)
        }
      }
    },
    // 弹出日历和关闭日历的回调
    openChangeOne(status) {
      if (status){
        this.yearShowOne =  true
      }
    },
    // 得到年份选择器的值
    panelChangeOne(value) {
      this.yearQuarter = value;
      this.yearShowOne = false;
    },
    async getList() {
      let time
      if (this.yearQuarter === null || this.yearQuarter === undefined) {
        time = ''
      }else {
        time = moment(this.yearQuarter).format('YYYY')
      }
      this.spinning = true
      const response = await get_active_list(this.searchName,this.searchTitle,this.menu[0],this.pagination.current,this.pagination.pageSize, time)
      if(response.code === 0){
        this.tableInfo = response.data
        this.pagination.total = response.count
      }else {
        this.$message.warning(response.message)
      }
      this.spinning = false
    },
    //获取专家列表
    async getExpertsId(name,pageNum,pageSize) {
      const response = await get_experts_id(name,pageNum,pageSize)
      if(response.code === 0){
        let list  = response.data
        for (let i=0;i<list.length;i++){
          this.specialistList.push({
            id: list[i].id,
            name: list[i].name,
            hospital:list[i].hospital
          })
        }
      }else {
        this.$message.warning(response.message)
      }
    },
    disabledDate(current) { // 限制日期选择
      return current && moment(current).format('YYYY') > moment().format('YYYY')
    },
    // //滚动条监听
    popupScroll_specialist(e){
      const {target} = e
      const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
      const clientHeight = target.clientHeight //滑动最低高度
      if(scrollHeight < clientHeight + 2){
        this.specialist_page_no++
        this.getExpertsId(this.specialist_title,this.specialist_page_no)
      }
    },
    // //鼠标搜索
    select_specialist(value){
      //首先清空数组，否则会数据重复
      this.specialistList = []
      this.specialist_title = value
      this.getExpertsId(value,1)
    },
    async getCodeList() {
      const response = await getColumnVodsList()
      if(response.code === 0){
        // this.typeInfo = response.data
        this.typeInfo = response.data.filter((word) => word.title !== "云ICU")
        this.typeInfo.unshift({id:1,title:"全部",code:"",delStatus:0})
        this.menu.push(this.typeInfo[0].code)
        this.codeList = response.data.filter((word) => word.title !== "云ICU")
        // this.codeList = [];
        // for(let i=0;i<response.data.length;i++){
        //   if(response.data[i].code !== "YUN_ICU"){
        //     this.codeList.push({
        //       code:response.data[i].code,
        //       delStatus:response.data[i].delStatus,
        //       id:response.data[i].id,
        //       title:response.data[i].title,
        //     })
        //   }
        // }
        await this.getList()
      }else {
        this.$message.warning(response.message)
      }
    },
    searchOk() {
      this.pagination.current = 1
      this.getList()
    },
    searchReset() {
      this.searchName = ''
      this.searchTitle = ''
      this.searchType = ''
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.yearQuarter = null
      this.getList()
    },
    pageChange(pageNum,pageSize) {
      this.pagination.current = pageNum
      this.pagination.pageSize = pageSize
      this.getList()
    },
    showSizeChange (pageNum,pageSize) { //分页器-每页条数
      this.pagination.current = pageNum
      this.pagination.pageSize = pageSize
      this.getList()
    },
    leftChange(e) {
      this.menu[0] = e
      this.getList()
    },
    async move(move, id) {
      const response = await move_active_list(id,move)
      if (response.code === 0) {
        this.$message.success("操作成功~")
        await this.getList()
      } else {
        this.$message.warning(response.message)
      }
    },
    // 推广确认btn
    stateSwitch(){

    },
    //上传图片
    async uploadImg(e,type) {
      let inputDOM = this.$refs.uploadImg_image.files
      for (let i = 0; i < inputDOM.length; i++) {
        let reader = new FileReader() // 读取文件对象
        reader.readAsDataURL(inputDOM[i]) //读取文件并将文件以URL的形式保存在result属性中 base64格式
        reader.onload = ((e)=> {
          let result = e.target.result // 获取base64格式图片
          const image = new Image() // 创建图片对象
          image.onerror = (e) => {
            this.$message.warning('图片加载失败，请联系管理员')
          }
          image.src = result // 将生成的base64格式作为图片地址
          image.onload = (async () => {
            if (this.styletype==='T_BOTTOM_3') {
              if (inputDOM.size > 1048576) {
                this.$message.warning('图片大小不能超过1M')
                return
              }
              if (image.width > 330) {
                this.$message.warning('图片宽度不能超过330')
                return
              }
            }else {
              if (inputDOM.size > 1048576 * 2) {
                this.$message.warning('图片大小不能超过2M')
                return
              }
              if (image.width > 1920) {
                this.$message.warning('图片宽度不能超过1920')
                return
              }
            }
            const response = await update(inputDOM[i]);
            if (response.code === 0) {
              if (type) this.imageList.push(response.data.url)
              else this.$set(this.addForm.cover, 0, response.data.url)
              this.$message.success("上传成功")
              document.getElementById('up_img').value = null;
            } else {
              //  上传失败
              this.$message.error("上传失败，请重试。", response.message)
            }
          })
        })
      }
      // return
      //
      // console.log(this.$refs.uploadImg_image.files)
      // return
      // if (file !== undefined) {
      //   if (file.size < 512000) {
      //
      //   } else {
      //     this.$message.error("图片尺寸请小于2mb")
      //   }
      // }
    },
    addBtn() {
      this.addForm = {
        templateCode:'T_BOTTOM_1',
        cover:[],
        title:'',
        intro:'',
        url:'',
        expertId:undefined,
        columnCode:undefined,
        type:'ARTICLE_SINGLE',
      }
      this.addType = 'add'
      this.addTitle = '新增'
      this.specialistList = []
      this.imageList = []
      this.specialist_page_no = 1
      this.specialist_title = ''
      this.getExpertsId()
      this.addShow = true
    },
    editBtn(item){
      this.addType = 'rest'
      this.addTitle = '修改'
      this.styletype = item.templateCode
      this.specialistList = []
      this.specialist_page_no = 1
      this.specialist_title = ''
      var a = item.expert.indexOf("-")
      var l = item.expert.substring(0, a);
      this.getExpertsId(l);
      this.getExpertsId()
      //赋值
      this.addForm = item
      if (item.templateCode === 'T_BOTTOM_3') {
        this.imageList = item.cover
      }
      this.addShow = true
    },
    styleType(value) {
      this.styletype = value
    },
    async addOk() {
      this.$refs.addItem.validate(async valid => {
        if (valid) {
          let form = this.addForm
          let data = {
            templateCode: form.templateCode,    //样式一：T_BOTTOM_1,样式二:T_BOTTOM_3,样式三:T_RIGHT_1
            cover: form.templateCode === 'T_BOTTOM_3' ? this.imageList : form.cover,   //图片连接
            title: form.title, //标题
            intro: form.intro,   //副标题，可以为空
            url: form.url,  //跳转连接
            expertId: form.expertId,     //作者id
            columnCode: form.columnCode, //栏目代码code
            // type: form.type //文章分类：ARTICLE_SINGLE
          }
          const response = await post_active_list(data)
          if (response.code === 0) {
            this.$message.success("操作成功~")
            await this.getList()
            //  清空
              this.$refs.addItem.resetFields()
            form.intro = ''
            form.expertId = undefined
            this.addShow = false
          } else {
            this.$message.warning(response.message)
          }
        } else {
          console.log('error submit!!')
          return false;
        }
      });

    },
    editOk() {
      this.$refs.addItem.validate(async valid => {
        if (valid) {
          let form = this.addForm
          let data = {
            "id":form.id,              //文章id
            "templateCode":form.templateCode,    //样式一：T_BOTTOM_1,样式二:T_BOTTOM_3,样式三:T_RIGHT_1
            "cover":form.templateCode === 'T_BOTTOM_3' ? this.imageList : form.cover,   //图片连接
            "title":form.title, //标题
            "intro":form.intro,   //副标题，可以为空
            "url":form.url,  //跳转连接
            "expertId":form.expertId,     //作者id
            "columnCode":form.columnCode, //栏目代码code
            "type":form.type //文章分类：ARTICLE_SINGLE

          }
          const response = await put_active_list(data)
          if (response.code === 0) {
            this.$message.success("操作成功~")
            await this.getList()
            //  清空
            this.$refs.addItem.resetFields()
            form.intro = ''
            form.expertId = undefined
            this.addShow = false
          } else {
            this.$message.warning(response.message)
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    async delTable(id) {
      const response = await del_active_list(id)
      if (response.code === 0) {
        this.$message.success("操作成功~")
        await this.getList()
      } else {
        this.$message.warning(response.message)
      }
    },
    cancel(){
      this.$message.warning('取消删除');
    },
    //删除图片数组
    delImg(index) {
      this.addForm.picture.splice(index, 1)
    },
    promotionsClose(){
      this.promotionsVisible = false
      this.appData = []
      this.promotionId = null
      this.subContentType = null
      this.getList() // 更新数据
    },
    async AppListSwitch(isOpen, row) {
      //推广
      if(isOpen){
        let data = [
          {
            contentId: this.promotionId,//内容id
            contentType: "ARTICLE",//一级内容
            columnCode: row.code,//栏目
            subContentType: this.subContentType//子级内容
          }
        ]
        await this.postAppSwitch(data,row)
      }else { //关闭推广
        await this.delAppSwitch(this.promotionId,row.code,'app',row)
      }
    },

    // 关闭成功
    async delAppSwitch(id,code,type,row) {
      let data = {
        code:code,
        type:type
      }
      const response = await delAppPromotion(id,data)
      if (response.code === 0) {
        //更新数据
        if(type === 'app'){
          this.appData.forEach((a,index) => {
            if (a.code === row.code) {
              this.$set(this.appData, index, a)
              return
            }
          })
        }
        this.$message.success("关闭成功~")
      } else {
        this.$message.warning(response.message)
      }
    },
    async postAppSwitch(data,row) {
      const response = await postAppPromotion(data)
      if (response.code === 0) {
        //更新数据
        this.appData.forEach((a,index) => {  
          if (a.code === row.code) {
            this.$set(this.appData, index, a)
            return
          }
        })
        this.$message.success("推广成功~")
      } else {
        this.$message.warning(response.message)
      }
    },

    async communityListSwitch(isOpen, row) {
      //推广
      if (isOpen) {
        let data = {
          type:this.subContentType,//内容类型
          contentId: this.promotionId,//内容id
          isOpen:1, //是否启用：0：否 1：是
          isTop: 0
        }
        await this.postcommunitySwitch(data, row)
      } else { //关闭推广
        await this.delAppSwitch(this.promotionId, this.subContentType, 'community', row)
      }
    },

    async postcommunitySwitch(data) {
      const response = await postcommunityPromotion(data)
      if (response.code === 0) {
        // //更新数据
        // this.appData.forEach((a,index) => {
        //   if (a.code === row.code) {
        //     this.$set(this.appData, index, a)
        //     return
        //   }
        // })
        this.$message.success("推广成功~")
      } else {
        this.$message.warning(response.message)
      }
    },
    async promotions(list) {
      this.promotionId = list.id
      this.subContentType = list.type
      //获取栏目
      this.getColumnList(list.id)
      //获取社区
      // this.getcommunityList(list.id)
      this.promotionsVisible = true

      // 小程序推广回显  
      const data = {
        contentId:this.promotionId,
        contentType:'ARTICLE',
        subContentType:this.subContentType,
      }
      const response = await getminaRecommend(data)
      if (response.code === 0) {
        if(response.data[0] && response.data[0].code === "YUN_ICU"){
           this.uniAppData[0].is_open = true
        }else{
          this.uniAppData[0].is_open = false
        }
      } else {
        this.$message.warning(response.message)
      }
    },
    async getcommunityList(id) {
      this.promotionSpin = true
      const response = await getcommunityPromotion(id,'ARTICLE_SINGLE')
      if (response.code === 0) {
        if(response.data.length && response.data[0].isOpen){
          this.appData[0].is_open = true
          this.appData[0].code = response.data[0].code
        }else {
          this.appData[0].is_open = false
        }
      } else {
        this.$message.warning(response.message)
      }
      this.promotionSpin = false
    },
    async getColumnList(id) {
      this.promotionSpin = true
      this.appData = []
      const response = await getColumnVodsList()
      if (response.code === 0) {
        this.appData = response.data
        this.appData.unshift({
          id:1,
          title:'社区首页',
          is_open:false
        })
        await this.getAppList(id)
      } else {
        this.$message.warning(response.message)
      }
      this.promotionSpin = false
    },
    async getAppList(id) {
      const response = await getAppPromotion(id)
      if (response.code === 0) {
        this.columnListApp = response.data
        //对比
        let app = this.appData
        let columnList = this.columnListApp
        app.forEach((a,index) =>{
          a.is_open = columnList.some(b =>{
            return b.code === a.code
          })
          this.$set(app,index,a)
        })
        await this.getcommunityList(id)
      } else {
        this.$message.warning(response.message)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.activeManager {
  margin: -15px -15px;
  background-color: rgba(240, 242, 245, 1);

  .search {
    background-color: white;
    height: 70px;
    display: flex;
    align-items: center;
    padding: 15px;
    margin-bottom: 1%;
  }

  .menu {
    background-color: white;
    width: 14%;
    height: 100%;
    margin-bottom: -1000px;
    padding-bottom: 1000px;
  }

  .center {
    background-color: white;
    width: 85%;
    margin-bottom: -1000px;
    padding: 15px 15px 1000px;

    ::v-deep .ant-table-title {
      padding: 0;
    }
  }
}

.uploadImg {
  border: #DDDDDD 2px dashed;
  width: 110px;
  height: 86px;
  margin-right: 5px;
  background-image: url("../../../assets/add.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  float: left;
}

.upload_gutter {
  border: #DDDDDD 2px dashed;
  width: 110px;
  height: 86px;
  margin-right: 5px;
  margin-bottom: 5px;
  float: left;
}
</style>
