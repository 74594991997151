import {get,post} from './base'

/**
 * 获取菜单
 * @returns {Promise<AxiosResponse<any> | void>}
 */
export function manageMenus(){
    return get("/api/admin/manageMenus")
}
// 菜单（一级）
export function getfirstMenu(){
    return get("/v3/munus/top")
}
// 菜单（二级）
export function getSecondThreeMenu(data){
    return get("/v3/munus/left",data)
}
