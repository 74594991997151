<template>
  <div class="footer">
    <!-- <div class="title">
      <span>{{ manageHeaderTitle }}</span>    
    </div> -->
    <div></div>
    <div class="info">
      <!-- <a style="color:#fff;font-size: 14px;margin-right: 3px;" @click="NewFeedback" v-if="done == 0" v-show="this.done2 == 0">
       <span>意见反馈</span>  
      </a> -->
      <!-- 页面初次显示数据 -->
      <!-- <a style="color:#fff;font-size: 14px;margin-right: 3px;" @click="NewFeedback" v-if="done == '' && this.done2 !== 0 && done !==0">
       <span>意见反馈</span> 
       <span>（未安排：{{this.done2}}条）</span>
      </a> -->
      <!-- vuex更新状态 -->
      <!-- <a style="color:#fff;font-size: 14px;margin-right: 3px;" @click="NewFeedback" v-if="done !== '' && done !== 0 ">
       <span>意见反馈</span>   
       <span>（未安排： {{done}}条）</span>
      </a> -->

      <!-- 页面初次显示数据 -->
      <a style="color:#fff;font-size: 14px;margin-right: 3px;" @click="NewFeedback" >
       <span>意见反馈</span> 
       <span v-if="done == '' && this.done2 !== 0 && done !==0">（未安排：{{this.done2}}条）</span>
       <span v-if="done !== '' && done !== 0 ">（未安排： {{done}}条）</span>
      </a>
      <div class="NotResolved" v-if="confirmed == '' && this.nowConfirmed !== 0 && confirmed !== 0">
        {{this.nowConfirmed}}
      </div>
      <!-- vuex更新状态 -->
      <div class="NotResolved" v-if="confirmed !== '' && confirmed !== 0 ">
        {{confirmed}}
      </div>
      <div class="header">
        <a-dropdown
          placement="bottomCenter">
          <a-avatar
              shape="square"
              size="default"
              :style="{ backgroundColor:'#1890ff' , verticalAlign: 'middle' }"
          >
            {{ user_name }}
          </a-avatar>
          <a-menu slot="overlay">
            <a-menu-item>
              个人信息
            </a-menu-item>
            <a-menu-item @click="log_out">
              退出登录
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getFeedback
} from '../service/NewFeedback'

export default {
  name: "ManageHeader",
  created(){
    this.getdone();
    this.getConfirmed()
  },
  data(){
    return {
      done2: '',
      nowConfirmed:'',
      all2: '',
      final:'',
    }
  },
  computed:{
    user_name(){
      const user_info = window.localStorage.getItem("user_info");
      const user_name =  JSON.parse(user_info).user_name
      return user_name.slice(user_name.length-1)
    },
    manageHeaderTitle(){
      return this.$store.state.manageHeaderTitle
    },
    all() {
      return this.$store.state.all
    },
    done() {
      return this.$store.state.done
    },
    confirmed() {
      return this.$store.state.confirmed
    }
  },
  methods:{
  //  退出登录
    log_out(){
      this.$router.push("/login")
    },
    NewFeedback() {
      this.$router.push("/NewFeedback")
    },
    async getConfirmed(type,degree,channel,content,status,pageSize,pageNum,memberId) { 
      var type = ''; var degree = ''; var channel = ''; var content = ''; var status = "待确认"; var pageNum = '' ; var pageSize =  '';var memberId = ""
      const response = await getFeedback(type,degree,channel,content,status,pageSize,pageNum,memberId)
      if (response.code === 0) {
        this.nowConfirmed = response.data.total
      } else {
        this.$message.warning(response.message)
      }
    },
    async getdone(type,degree,channel,content,status,pageSize,pageNum,memberId) { 
      var type = ''; var degree = ''; var channel = ''; var content = ''; var status = "待安排"; var pageNum = '' ; var pageSize =  '';var memberId = ""
      const response = await getFeedback(type,degree,channel,content,status,pageSize,pageNum,memberId)
      if (response.code === 0) { 
        this.done2 = response.data.total
      } else {
        this.$message.warning(response.message)
      }
    },
  }
}
</script>

<style scoped lang="scss">
.footer{
  height: 64px;
  display: flex;
  justify-content: space-between;
  width: 36%;
  .title{
    font-size: 15px;
    line-height: 64px;
    font-weight: 800;
    padding: 0 24px;
  }
  .info{
    display: flex;
    font-size: 15px;
    line-height: 64px;
    padding: 0 24px;
    align-items: center;
    .header{
      padding: 0 24px;
      
    }
    .NotResolved{
     height: 18px;
     width: 18px;
     background-color: red;
     color:#fff;
     line-height: 18px;
    //  margin-top: 20px;
     text-align: center;
     border-radius: 50%;
     transform: translateY(-2px);
     font-size:14px
    }
    .NotResolvedd{
     height: 18px;
     width: 18px;
     background-color: yellow;
     color:black;
     line-height: 18px;
    //  margin-top: 20px;
     text-align: center;
     border-radius: 50%;
     transform: translateY(-2px);
     font-size:14px
    }
  }
}
</style>