<template>
  <div style="margin: -15px -15px;">
    <a-spin :spinning="spinning">
    <!-- 搜索框 -->
    <div class="registerMember-right-top">
      <!--   顶部搜索  -->
      <div style="height: 70px;;display: flex; text-align: center;align-items: center; border-bottom: 10px solid #f5f5f5;">
        <div class="registerMember-right-top-input">
          手机号码:
          <a-input
            v-model="searchForm.number"
            style="width: 60%"
            @pressEnter="enterBtn"
            placeholder="请输入"
          />
        </div>
        <div class="registerMember-right-top-input">
          姓名:
          <a-input
            v-model="searchForm.name"
            style="width: 60%"
            @pressEnter="enterBtn"
            placeholder="请输入"
          />
        </div>
        <div class="registerMember-right-top-input">
          状态:
          <a-radio-group
            style="margin-left: 5px;margin-right:10px"
            v-model="searchForm.certStatus"
            @change="enterBtn"
          >
            <a-radio-button value=""> 全部 </a-radio-button>
            <a-radio-button value="CERTIFIED"> 已认证 </a-radio-button>
            <!-- <a-radio-button value="IN_REVIEW"> 待审核 </a-radio-button> -->
            <a-radio-button value="UNCERTIFIED"> 未认证 </a-radio-button>
          </a-radio-group>
        </div>
        <div>
        <a-button @click="selectBtn" style="margin-left: 20px" type="primary"
          >查询</a-button
        >
        <a-button @click="restBtn" style="margin-left: 10px"
          >重置</a-button
        >
      </div>
      </div>
    </div>
    <!-- table -->
    <div class="registerMember-right-table" style="padding: 20px 15px 0 15px">
      <!--  列表上方按钮      -->
      <div class="registerMember-right-table-top-btn" style=" height: 50px;">
        <a-button @click="addBtn" type="primary">新增</a-button>
        <a-popconfirm
          style="margin-left: 10px"
          placement="top"
          ok-text="是"
          cancel-text="否"
          @confirm="resetConfirm"
          :disabled="tableRemoveAllDisabled"
        >
          <template slot="title">
            <p>是否重置密码？</p>
          </template>
          <a-button  @click="selectJudgment()">重置密码</a-button>
        </a-popconfirm>
        <a-button @click="expotExcel()" style="float: right;z-index: 11;position: relative;margin-bottom:15px">
          <a-icon type="file-excel" style="color: green" /> 批量导出
        </a-button>
        <!-- <a-button style="margin-left: 10px" @click="amendBtn"
          >修改</a-button
        > -->
      </div>
      <!--   列表   -->
      <div class="registerMember-right-table-top-table">
        <a-table
          :scroll="{ x: 1500 }"
          :pagination="false"
          style="width: 99%"
          :columns="columns"
          :rowKey="(record) => record.id"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
          :data-source="tableList"
        >
          <!-- mina: 小程序
          mp: 公众号 
          H5 windows 桌面端 Mac 桌面端 web: 网页端 
          app_ios: 原生 iOS 端
           app_android: 原生 Android 端,
          导入名单注册import -->
          <span slot="registerSlotChannel" slot-scope="text, record">
            <span v-if="record.registerChannel == 'mina'">小程序</span>
            <span v-if="record.registerChannel == 'mp'">公众号</span>
            <span v-if="record.registerChannel == 'h5' || record.registerChannel == 'windows' || record.registerChannel == '桌面端'||record.registerChannel == 'Mac' || record.registerChannel == 'web'">网页端</span>
            <span v-if="record.registerChannel == 'app_ios'">原生iOS端</span>
            <span v-if="record.registerChannel == 'app_android' || record.registerChannel == 'app_android' ">原生Android端</span>
            <span v-if="record.registerChannel == 'import' || record.registerChannel == '导入名单注册'">导入名单注册</span>
            <span v-if="record.registerChannel == '后台添加'">后台添加</span>
            <span v-if="record.registerChannel == 'live_h5'">live_h5</span>  
            <span v-if="record.registerChannel == null">未知</span>
          </span>
          <span slot="certStatus" slot-scope="text, record">
                  <div v-if="text === 'AGREED'">
                    <admin_con
                      style="color: #02ac27"
                      class="icon"
                      type="icon-yuandian1"
                    />已认证
                  </div>
                  <div v-if="text === 'PENDING'">
                    <admin_con
                      style="color: #0e77d1"
                      class="icon"
                      type="icon-yuandian1"
                    />待审核
                  </div>
                  <div v-if="text === 'UNCERTIFIED'">
                    <admin_con
                      style="color: #a1a3a2"
                      class="icon"
                      type="icon-yuandian1"
                    />未认证
                  </div>
                  <div v-if="text === 'REJECT'">
                    <admin_con
                      style="color: #a1a3a2"
                      class="icon"
                      type="icon-yuandian1"
                    />已拒绝
                  </div>
                </span>
          <!-- <span slot="hospitalName" slot-scope="text, record">
            <span v-if="record.hospitalName">{{
              record.hospitalName
            }}</span>
            <span v-if="record.school">{{ record.school }}</span>
            <span v-if="record.workUnit">{{ record.workUnit }}</span>
          </span> -->
          <span slot="operation" slot-scope="text, record">
            <!-- <a-popconfirm
              placement="top"
              ok-text="是"
              cancel-text="否"
              @confirm="resetConfirmTable(record.id)"
            >
              <template slot="title">
                <p>是否重置密码？</p>
              </template>
              <a>重置密码</a>
            </a-popconfirm> -->
            <a @click="sign(record)" style="margin-right:5px">标记</a>
            <a @click="amendBtnTable(record.id)" >修改</a>
            <a @click="ViewRecords(record.id)" style="margin-left: 8px">查看更新记录</a>
          </span>
        </a-table>
      </div>
      <!--      分页-->
      <div style="width: 100%">
        <div class="pageClass">
          <a-pagination
            :current="pageNo"
            @change="pageChange"
            :total="total"
            show-quick-jumper
            show-size-changer
          />
        </div>
      </div>
    </div>
    <!-- 新增框 -->
    <a-drawer
      :title="drawerTitle"
      placement="right"
      :closable="false"
      :visible="addVisible"
      @close="addClose"
      width="500"
    >
      <div class="addForms">
        <a-form-model
          ref="addRuleForm"
          :model="addForm"
          :rules="rules2"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <!-- <a-form-model-item label="身份" prop="type">
            <a-select
              show-search
              option-filter-prop="children"
              @change="selectNumberType"
              placeholder="请选择"
              v-model="addForm.type"
              style="width: 300px"
            >
              <a-select-option value="1001"> 医生 </a-select-option>
              <a-select-option value="1002"> 护士 </a-select-option>
              <a-select-option value="1003"> 医技 </a-select-option>
              <a-select-option value="1004"> 药师 </a-select-option>
              <a-select-option value="1005"> 学生 </a-select-option>
              <a-select-option value="1006">
                其他医务从业者
              </a-select-option>
              <a-select-option value="1007"> 非医务从业者 </a-select-option>
            </a-select>
          </a-form-model-item> -->
          <a-form-model-item label="姓名" prop="name">
            <a-input
              style="width: 300px"
              v-model="addForm.name"
              placeholder="请输入"
            />
          </a-form-model-item>
          <a-form-model-item label="手机号" prop="tel">
            <a-input
              style="width: 300px"
              v-model="addForm.tel"
              placeholder="请输入"
            />
          </a-form-model-item>
          <a-form-model-item label="省份/城市" prop="province">
            <a-cascader
              style="width: 300px"
              v-model="addForm.province"
              :options="hospitaloPtions"
              placeholder="请选择"
              @change="provinceChange"
            />
          </a-form-model-item>
          <!------------------------------- 不需要1001 ~ 1004---------------------------------->
          <!-- <a-form-model-item
            v-if="
              addForm.type === '1001' ||
              addForm.type === '1002' ||
              addForm.type === '1003' ||
              addForm.type === '1004'
            "
            label="医院"
            prop="hospitalName"
          >
            <a-select
              style="width: 300px"
              v-model="addForm.hospitalName"
              placeholder="请选择"
              @change="hospitalSeleChange"
            >
              <a-select-option
                v-for="item in hospitalNameList"
                :value="item.name"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            v-if="
              addForm.type === '1001' ||
              addForm.type === '1002' ||
              addForm.type === '1003' ||
              addForm.type === '1004'
            "
            label="科室"
            prop="hospitalOffices"
          >
            <a-cascader
              style="width: 300px"
              v-model="addForm.hospitalOffices"
              :options="hospitalOfficesList"
              placeholder="请选择"
              @change="officeChange"
            />
          </a-form-model-item>
          <a-form-model-item
            v-if="
              addForm.type === '1001' ||
              addForm.type === '1002' ||
              addForm.type === '1003' ||
              addForm.type === '1004'
            "
            label="职称"
            prop="jobTitle"
          >
            <a-select  
              style="width: 300px"
              v-model="addForm.jobTitle"
              placeholder="请选择"
              @change="jobTitleChange"
            >
              <a-select-option
                v-for="item in jobTitleList"
                :value="item.jobTitle"
              >
                {{ item.jobTitle }}
              </a-select-option>
            </a-select>
          </a-form-model-item> -->
          <!-------------------------------1005------------------------------------------->
          <a-form-model-item
            label="学院"
            prop="school"
          >
            <a-select
              style="width: 300px"
              v-model="addForm.school"
              placeholder="请选择"
              @popupScroll="popupScroll_describeCode"
            >
              <a-select-option
                v-for="item in schoolListData"
                :value="item.name"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            label="学历"
            prop="education"
          >
            <a-select
              style="width: 300px"
              placeholder="请选择"
              v-model="addForm.education"
            >
              <a-select-option
                v-for="item in educationList"
                :value="item.education"
              >
                {{ item.education }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            label="专业"
            prop="specialty"
          >
            <a-input
              style="width: 300px"
              v-model="addForm.specialty"
              placeholder="请输入"
            />
          </a-form-model-item>
          <!-------------------------------不需要 1006  ~  1007------------------------------------------->
          <!-- <a-form-model-item
            v-if="addForm.type === '1006' || addForm.type === '1007'"
            label="单位"
            prop="workUnit"
          >
            <a-input
              style="width: 300px"
              v-model="addForm.workUnit"
              placeholder="请输入"
            />
          </a-form-model-item>
          <a-form-model-item
            v-if="addForm.type === '1006' || addForm.type === '1007'"
            label="职务"
            prop="post"
          >
            <a-input
              style="width: 300px"
              v-model="addForm.post"
              placeholder="请输入"
            />
          </a-form-model-item> -->
          <!--      新增时共有的        -->
          <a-form-model-item label="性别">
            <a-select
              style="width: 300px"
              placeholder="请选择"
              v-model="addForm.sex"
            >
              <a-select-option v-for="item in sexList" :value="item.value">
                {{ item.sex }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="邮箱">
            <a-input
              style="width: 300px"
              v-model="addForm.email"
              placeholder="请输入"
            />
          </a-form-model-item>
          <a-form-model-item label="生日">
            <a-date-picker
              placeholder="请选择"
              style="width: 300px"
              v-model="addForm.birthday"
            />
          </a-form-model-item>
          <a-form-model-item label="认证状态">
            <a-input
              :disabled="true"
              style="width: 300px"
              v-model="addForm.certStatus"
              placeholder="请输入"
            />
          </a-form-model-item>
          <a-form-model-item label="注册渠道">
            <a-input
              :disabled="true"
              style="width: 300px; margin-bottom: 20px"
              v-model="addForm.registerChannel"
              placeholder="请输入"
            />
          </a-form-model-item>
        </a-form-model>
      </div>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="addClose">
          取消
        </a-button>
        <a-button type="primary" @click="onSubmit"> 确认 </a-button>
      </div>
    </a-drawer>
    <!-- 修改框 -->
    <ListPackaging></ListPackaging>
    <!-- 会员资料更新记录 -->
    <a-drawer
      title="会员资料更新记录"
      placement="right"
      :visible="updateVisible"
      @close="updateClose"
      width="800"
    >
      <a-button @click="exportUpdateExcel()" style="float: right;z-index: 11;position: relative;margin-bottom:15px">
        <a-icon type="file-excel" style="color: green" /> 批量导出
      </a-button>
      <a-table 
        :columns="tableColumns" 
        :data-source="tableData" 
        :pagination="false"
        :row-key="(record) => record.id"
        :scroll="{ x: 900 }">
        <span slot="updateLocation" slot-scope="text, record">
          <span v-if="record.channel == '后台'">{{record.location}}</span>
          <span v-else>{{record.channel}}</span>
        </span> 
      </a-table>
      <div
          style="
          margin-top: 28px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          "
        >
          <span
            style="
              font-size: medium;
              color: #929292;
              font-size: 14px;
              bottom: 3%;
            "
          >
            共{{ pagination.total }}条记录 第 {{ pagination.pageNum }} /
            {{ Math.ceil(pagination.total / pagination.pageSize) }} 页
          </span>
          <a-pagination
            style="float: right"
            show-quick-jumper
            show-size-changer
            :page-size.sync="pagination.pageSize"
            :total="pagination.total"
            v-model="pagination.current"
            @change="page2Change"
            @showSizeChange="page2Change"
          />
      </div>
    </a-drawer>
    <!-- 已认证图片弹窗 -->
    <a-modal
      v-model="modaLshow"
      title="认证材料"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <img style="width:100%;height: auto;" :src="authenticationUrl" alt="" >
      <span v-if="authenticationStatus == true" style="font-size:16px">暂无数据</span>
    </a-modal>

     <!-- 已认证图片弹窗 -->
     <a-modal
        v-model="modaL2show"
        title="标记"
        @ok="handle2Ok"
        @cancel="handle2Cancel"
        width="330px"
      >
        是否完善资料：
        <a-switch
          :checked="isSummit !== 0"
          v-model="is_open"
          checked-children="是"
          un-checked-children="否"
          @click="communityListSwitch(is_open)"
          default-checked
        />
        <br>
        <span style="margin-top:20px;display: inline-block;">是否内部人员：</span> 
        <a-switch
          :checked="isStaff !== 0"
          v-model="isStaff_is_open"
          checked-children="是"
          un-checked-children="否"
          @click="community2ListSwitch(isStaff_is_open)"
          default-checked
        />
        <div style="height: 60px;"></div>
     </a-modal>
  </a-spin>
  </div>
</template>

<script>
import City from "@/assets/city.json";
import {
  getMemberList,
  postMember,
  putMember,
} from "@/service/MedicalConference_y";
import {
  putSummit
} from "@/service/InternalMemberManagement_api";
import { delAdminList, restPassWorld } from "@/service/recruitment";
import ListPackaging from "@/views/content/case/ListPackaging";
import { admin_icon } from "@/utils/myIcon_f";
import { getLoginDepartment , getMemberUpdate,getMemberExport,getStudentRegisterExport} from "@/service/dataCleaned_api";
import { getHospital } from "@/service/hospital_api";
import { getTitle } from "@/service/dataDictionary_api";
import { getSchool } from "@/service/school_api";
import { educationList, sexDate } from "@/utils/dateReturn";
import {getAuditId} from "@/service/memberImport_api";
import moment from "moment";
import { info } from 'sass';
export default {
  created(){
    this.getList()
    this.getHospitalOfficesList()
    // this.getJobTitleList(this.addForm.type, 1);
    this.$store.dispatch("setManageHeaderTitle", "注册会员管理");
  },
  components: {
    ListPackaging,
    admin_con: admin_icon,
  },
  data(){
    return {
      isSummit:0,
      isStaff:0,
      isStaff_is_open:false,
      is_open:false,
      modaL2show:false,
      hospital1Name:"",
      page2num: 1,
      province: "",
      city:"",
      authenticationStatus:false,
      authenticationUrl:"",
      modaLshow:false,
      pagination: {
          //存储大table的分页
          total: 0,
          current: 1,
          pageSize: 10,
          pageNum: 1,
      },
      tableColumns: [
          {
            title: '更新位置',
            // dataIndex: 'content',
            width: '18%',
            dataIndex: 'location',
            // scopedSlots: { customRender: 'updateLocation' }
          },
          {
            title: '更新前内容',
            dataIndex: 'frontContent',
            width: '18%'
          },
          {
            title: '更新后内容',
            dataIndex: 'laterContent',
            width: '21%',
          },
          {
            title: '更新渠道',
            dataIndex: 'channel',
            width: '21%',
          },
          {
            title: '操作人',
            dataIndex: 'operatorName',
            width: '21%',
          },
          {
            title: '更新时间',
            dataIndex: 'createdTime',
            width: '21%',
          }
        ],
      tableData: [],
      updateVisible:false,
      user_id: [],
      City: City,
      educationList: educationList(),
      sexList: sexDate(),
      schoolListData: [],
      jobTitleList: [],
      hospitalNameList: [],
      hospitalNameList_pageNo: 1,
      hospitalOfficesList: [],
      drawerTitle:"新增",
      hospitaloPtions: [],
      addVisible: false,
      total: 0,
      pageNo: 1,
      selectedRowKeys: [],
      tableList: [],
      labelCol: { span: 5 },
      wrapperCol: { span: 14 },
      addForm: {
        type: "1001",
        name: "",
        tel: "",
        province: undefined,
        city: "",
        hospitalName: undefined,
        hospitalOffices: undefined,
        jobTitle: undefined,
        school: undefined,
        education: undefined,
        specialty: "",
        workUnit: "",
        post: "",
        sex: undefined,
        email: "",
        birthday: "",
        certStatus: "未认证",
        registerChannel: "后台添加",
      },
      rules: {},
      //1001-1004
      rules1: {
        type: [{ required: true, message: "请选择", trigger: "blur" }],
        name: [{ required: true, message: "请输入", trigger: "change" }],
        tel: [{ required: true, message: "请输入", trigger: "change" }],
        province: [{ required: true, message: "请选择", trigger: "change" }],
        hospitalName: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        hospitalOffices: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        jobTitle: [{ required: true, message: "请选择", trigger: "change" }],
      },
      //1005
      rules2: {
        // type: [{ required: true, message: "请选择", trigger: "blur" }],
        name: [{ required: true, message: "请输入", trigger: "change" }],
        tel: [{ required: true, message: "请输入", trigger: "change" }],
        province: [{ required: true, message: "请选择", trigger: "change" }],
        school: [{ required: true, message: "请选择", trigger: "change" }],
        education: [{ required: true, message: "请选择", trigger: "change" }],
        specialty: [{ required: true, message: "请输入", trigger: "change" }],
      },
      //1006-1007
      rules3: {
        type: [{ required: true, message: "请选择", trigger: "blur" }],
        name: [{ required: true, message: "请输入", trigger: "change" }],
        tel: [{ required: true, message: "请输入", trigger: "change" }],
        province: [{ required: true, message: "请选择", trigger: "change" }],
        workUnit: [{ required: true, message: "请输入", trigger: "change" }],
        post: [{ required: true, message: "请输入", trigger: "change" }],
      },
      //顶部搜索
      searchForm: {
        number: "",
        name: "",
        certStatus: "",
      },
      columns: [
        {
          title: "姓名",
          dataIndex: "name",
          width: "10%",
          key: "name",
          align: "center",
        },
        {
          title: "手机号码",
          dataIndex: "tel",
          key: "tel",
          align: "center",
          width: "15%",
        },
        {
          title: "学院",
          dataIndex: "school",
          key: "school",
          width: "12%",
          align: "center",
        },
        {
          title: "学历",
          dataIndex: "education",
          key: "education",
          width: "12%",
          align: "center",
        },
        {
          title: "专业",
          dataIndex: "specialty",
          key: "specialty",
          width: "12%",
          align: "center",
        },
        // {
        //   title: "省份-城市",
        //   // dataIndex: "tel",
        //   // key: "tel",
        //   // align: "center",
        //   scopedSlots: { customRender: "provinceAndCity" },
        //   width: "15%",
        // },
        // {
        //   title: "医院/单位",
        //   dataIndex: "hospitalName",
        //   width: "20%",
        //   align: "hospitalName",
        //   key: "tel2",
        //   scopedSlots: { customRender: "hospitalName" },
        // },
        // {
        //   title: "科室",
        //   dataIndex: "hospitalOffices",
        //   key: "hospitalOffices",
        //   ellipsis: true,
        //   width: "10%",
        //   align: "center",
        //   scopedSlots: { customRender: "hospitalOffices" },
        // },
        // {
        //   title: "职称",
        //   dataIndex: "jobTitle",
        //   key: "jobTitle",
        //   ellipsis: true,
        //   width: "10%",
        //   align: "center",
        // },
        // {
        //   title: "职务",
        //   dataIndex: "post",
        //   key: "post",
        //   ellipsis: true,
        //   width: "20%",
        //   align: "center",
        // },
        {
          title: "认证状态",
          dataIndex: "certifyRecordStatus",
          key: "certifyRecordStatus",
          ellipsis: true,
          width: "12%",
          align: "center",
          scopedSlots: { customRender: "certStatus" },
        },
        // {
        //   title: "平台专家",
        //   dataIndex: "createTime",
        //   key: "createTime",
        //   width: "10%",
        //   align: "center",
        //   scopedSlots: { customRender: "createTime" },
        // },
        {
          title: "注册渠道",
          dataIndex: "registerChannel",
          key: "registerChannel",
          width: "20%",
          align: "center",
          // scopedSlots: { customRender: "registerSlotChannel" },
        },
        {
          title: "注册时间",
          dataIndex: "createdTime",
          key: "createdTime",
          width: "20%",
          align: "center",
        },
        {
          title: "操作",
          dataIndex: "operation",
          key: "address 4",
          fixed: "right",
          width: 200,
          // align: "center",
          scopedSlots: { customRender: "operation" },
        },
      ],
      spinning:false,
      selectedRows:[],
      tableRemoveAllDisabled:false,
      memberId:'',
    }
  },
  watch: {
    //监听对话框处于关闭状态下，将选择框置空
    listBoxIsShow(newVal) {
      if (!newVal) {
        //选择的列表的id
        this.user_id = [];
        this.selectedRowKeys = [];
        this.getList();
      }
    },
  },
  computed: {
    //  vuex-名单对话框数据
    listBox() {
      return this.$store.state.listDialogBox;
    },
    listBoxIsShow() {
      return this.$store.state.listDialogBox.isShow;
    },
  },
  mounted() {
    // this.rules = this.rules1;
    this.getPCH();
  },
  methods: {
    sign(record){
      this.modaL2show = true
      this.isSummit = record.isSummit
      this.is_open = record.isSummit === 1?true:false
      this.memberId = record.id
      this.isStaff = record.isStaff
      this.isStaff_is_open = record.isStaff === 1?true:false
      // console.log(this.memberId)
    },
    async communityListSwitch(isOpen) {
        const response = await putSummit(this.memberId,this.isStaff,isOpen === false?0:1);
        if (response.code === 0) {
          this.$message.success("操作成功");
          this.getList()
        } else {
          this.$message.warning(response.message);
        }
      },
      //   是否内部人员
      async community2ListSwitch(isOpen) {
        const response = await putSummit(this.memberId,isOpen === false?0:1,this.isSummit);
        if (response.code === 0) {
          this.$message.success("操作成功");
          this.getList()
        } else {
          this.$message.warning(response.message);
        }
      },
    handle2Ok(){
      this.modaL2show = false
    },
    handle2Cancel(){
      this.modaL2show = false
    },
    async expotExcel(){
        this.spinning = true
        let form = this.searchForm;
        let data = {
          name: form.name,
          tel: form.number,
          type:"YXS", 
          certStatus: form.certStatus, //状态好像也要变
          ExelName: "医学生会员注册记录",
        };
        // console.log("传参",data)
        const response = await getStudentRegisterExport(data);
        this.spinning = false
    },
    // 导出
    async exportUpdateExcel() {
      const data = {
        memberId:this.updateMemberid,
        ExelName: "会员资料更新记录",
      }
      const response = await getMemberExport(data);
    },
    // 学院的滚动条  
    popupScroll_describeCode(e) {
      const { target } = e;
      const scrollHeight = target.scrollHeight - target.scrollTop; //滑动总高度
      const clientHeight = target.clientHeight; //滑动最低高度
      if (scrollHeight < clientHeight + 2) {
        this.page2num++;
        //let data = {
        //  province: this.province,
        //  city: this.city,
        //  pagenum: this.hospitalNameList_pageNo++,
        //  pageSize:10 
        //};
        this.getschoolList();
      }
    },
    handleOk(){
      this.modaLshow = false
    },
    handleCancel(){
      this.modaLshow = false
    },
    async getDetail(record){ 
      // 开弹窗
      this.modaLshow = true
      const Info = await getAuditId(record.id)
      if (Info.code === 0) {
        if(Info.data.length !== 0){
          this.authenticationStatus = false
          if( Info.data[0].practiceLicenseUrl){
            this.authenticationUrl = Info.data[0].practiceLicenseUrl
          }else if(Info.data[0].employeeCardUrl){
            this.authenticationUrl = Info.data[0].employeeCardUrl
          }else if(Info.data[0].nvqUrl)
            this.authenticationUrl = Info.data[0].nvqUrl
          else{
            this.authenticationUrl = ""
            this.authenticationStatus = true
          }
        }else{
          this.authenticationUrl = ""
          this.authenticationStatus = true
        }
      } else {
        this.$message.warning(response.message);
      }
      },
    // 分页
    page2Change(page_no, page_size) {
      this.pagination.current = page_no;
      this.pagination.pageSize = page_size;
      this.getUpdateMember();
    },
    async getList() {
      this.spinning = true;
      let form = this.searchForm;
      let data = {
        name: form.name,
        tel: form.number,
        certStatus: form.certStatus,
        pageNo: this.pageNo,
        pageSize:10,
        // type: this.selectId[0],
        type: "YXS", 
      };
      const response = await getMemberList(data);
      if (response.code === 0) {
        // console.log("医学生",response.data)
        this.tableList = response.data;
        this.total = response.count;
      } else {
        this.$message.warning(response.message);
      }
      this.spinning = false;
    },
    enterBtn() {
      this.pageNo = 1
      this.getList();
    },
    restBtn() {
      let form = this.searchForm;
      form.number = "";
      form.name = "";
      form.certStatus = ""
      this.pageNo = 1
      this.getList();
    },
    addBtn() {
      this.drawerTitle = "新增";
      this.addVisible = true;
    },
    resetConfirm() {
      const userId = this.user_id;
      if (userId !== "" && userId !== null && userId !== undefined) {
        this.restPass(userId);
      } else {
        this.$message.warning("请选择需要重置密码的列表~");
      }
    },
    selectJudgment() {  // 如果没有选择不出现弹框
      this.user_id.length === 0 || this.user_id == null || this.user_id == undefined? this.tableRemoveAllDisabled = true : this.tableRemoveAllDisabled = false
      if (this.user_id.length === 0 || this.user_id == null || this.user_id == undefined) {
        this.$message.warning('请选择相关内容再操作')
      }   
    },
    onSelectChange(selectedRowKeys) {
      //选择的列表的id
      this.user_id = selectedRowKeys; 
      this.selectedRowKeys = selectedRowKeys;
    },
    amendBtn() {
      let listId = this.user_id;
      if (listId.length === 1) {
        this.drawerTitle = "修改";
        //获取单个列表内容
        // this.getListOne(listId[0])
        // this.addVisible = true
        this.listBox.memberId = listId[0];
        //打开对话框 -- ListPackaging
        this.listBox.isShow = true;
      } else {
        this.$message.warning("请选择想要修改的一个列表~");
      }
    },
    amendBtnTable(id) {
      this.listBox.memberId = id;
      // console.log(this.listBox);
      //打开对话框 -- ListPackaging
      this.listBox.isShow = true;
    },
    async ViewRecords(id){
        this.updateVisible = true
        this.updateMemberid = id
        this.getUpdateMember()
      },
      // 会员更新记录接口
      async getUpdateMember(){
        const data = {
          memberId:this.updateMemberid,
          pageNo: this.pagination.current,
          pageSum: this.pagination.pageSize
        }
        const response = await getMemberUpdate(data)
        if(response.code === 0){ 
          // console.log("更新记录",response.data)
          this.tableData = response.data;
          this.pagination.total = response.count;
        }else {
          this.$message.warning(response.message)
        }
      },
    pageChange(pageNo) {
      this.pageNo = pageNo;
      this.getList();
    },
    selectBtn() {
      this.getList();
    },
    selectNumberType() {
      // this.$set(this.addForm, "jobTitle", undefined);
      // //其他医务从业者--医务从业者不需要选择省份城市
      // if (this.addForm.type !== "1006" || this.addForm.type !== "1007") {
      //   this.getJobTitleList(this.addForm.type, 1);
      // }
      // //选择身份时切换rules校验
      // switch (this.addForm.type) {
      //   case "1001": {
      //     this.rules = this.rules1;
      //     break;
      //   }
      //   case "1002": {
      //     this.rules = this.rules1;
      //     break;
      //   }
      //   case "1003": {
      //     this.rules = this.rules1;
      //     break;
      //   }
      //   case "1004": {
      //     this.rules = this.rules1;
      //     break;
      //   }
      //   case "1005": {
      //     this.rules = this.rules2;
      //     break;
      //   }
      //   case "1006": {
      //     this.rules = this.rules3;
      //     break;
      //   }
      //   case "1007": {
      //     this.rules = this.rules3;
      //     break;
      //   }
      //   default: {
      //     break;
      //   }
      // }
    },
    provinceChange(value) {
      //let data = {
      //  province: value[0],
      //  city: value[1],
      //};
      this.schoolListData = []
      this.province = value[0]
      this.city = value[1]
      this.page2num = 1
      this.addForm.school = undefined  //清空之前选择的学院
      this.getschoolList();
    },
    addClose() {
      this.addVisible = false;
      //清空
      this.schoolListData = []
      this.selectedRowKeys = [];
      this.user_id = [];
      this.$refs.addRuleForm.resetFields();
      let form = this.addForm;
      form.birthday = undefined;
      form.sex = undefined;
      form.email = "";
      form.certStatus = "未认证";
      form.registerChannel = "后台添加";
    },
    updateClose() {
      this.updateVisible = false;
      this.pagination.pageSize = 10
      this.pagination.current = 1
    },
    // 提交新增的表单
    onSubmit() {
      this.$refs.addRuleForm.validate((valid) => {
        if (valid) {
          let form = this.addForm;
          let data = {};
          // if (this.drawerTitle === "新增") {
            // switch (form.type) {
            //   case "1001": {
            //     data = {
            //       type: form.type,
            //       name: form.name,
            //       tel: form.tel,
            //       hospitalName: form.hospitalName,
            //       hospitalOffices:
            //         form.hospitalOffices[0] + "-" + form.hospitalOffices[1],
            //       jobTitle: form.jobTitle,

            //       province: form.province[0],
            //       city: form.province[1],

            //       sex: form.sex,
            //       email: form.email,
            //       birthday: form.birthday
            //         ? moment(form.birthday).format("YYYY-MM-DD")
            //         : "",
            //       certStatus: form.certStatus,
            //       registerChannel: form.registerChannel,
            //       nvqUrl: "",
            //     };
            //     break;
            //   }
            //   case "1002": {
            //     data = {
            //       type: form.type,
            //       name: form.name,
            //       tel: form.tel,
            //       hospitalName: form.hospitalName,
            //       hospitalOffices:
            //         form.hospitalOffices[0] + "-" + form.hospitalOffices[1],
            //       jobTitle: form.jobTitle,

            //       province: form.province[0],
            //       city: form.province[1],

            //       sex: form.sex,
            //       email: form.email,
            //       birthday: form.birthday
            //         ? moment(form.birthday).format("YYYY-MM-DD")
            //         : "",
            //       certStatus: form.certStatus,
            //       registerChannel: form.registerChannel,
            //       nvqUrl: "",
            //     };
            //     break;
            //   }
            //   case "1003": {
            //     data = {
            //       type: form.type,
            //       name: form.name,
            //       tel: form.tel,
            //       hospitalName: form.hospitalName,
            //       hospitalOffices:
            //         form.hospitalOffices[0] + "-" + form.hospitalOffices[1],
            //       jobTitle: form.jobTitle,

            //       province: form.province[0],
            //       city: form.province[1],

            //       sex: form.sex,
            //       email: form.email,
            //       birthday: form.birthday
            //         ? moment(form.birthday).format("YYYY-MM-DD")
            //         : "",
            //       certStatus: form.certStatus,
            //       registerChannel: form.registerChannel,
            //       nvqUrl: "",
            //     };
            //     break;
            //   }
            //   case "1004": {
            //     data = {
            //       type: form.type,
            //       name: form.name,
            //       tel: form.tel,
            //       hospitalName: form.hospitalName,
            //       hospitalOffices:
            //         form.hospitalOffices[0] + "-" + form.hospitalOffices[1],
            //       jobTitle: form.jobTitle,

            //       province: form.province[0],
            //       city: form.province[1],

            //       sex: form.sex,
            //       email: form.email,
            //       birthday: form.birthday
            //         ? moment(form.birthday).format("YYYY-MM-DD")
            //         : "",
            //       certStatus: form.certStatus,
            //       registerChannel: form.registerChannel,
            //       nvqUrl: "",
            //     };
            //     break;
            //   }
            //   case "1005": {
            //     data = {
            //       type: form.type,
            //       name: form.name,
            //       tel: form.tel,
            //       school: form.school,
            //       education: form.education,
            //       specialty: form.specialty,

            //       province: form.province[0],
            //       city: form.province[1],

            //       sex: form.sex,
            //       email: form.email,
            //       birthday: form.birthday
            //         ? moment(form.birthday).format("YYYY-MM-DD")
            //         : "",
            //       certStatus: form.certStatus,
            //       registerChannel: form.registerChannel,
            //       nvqUrl: "",
            //     };
            //     break;
            //   }
            //   case "1006": {
            //     data = {
            //       type: form.type,
            //       name: form.name,
            //       tel: form.tel,
            //       workUnit: form.workUnit,
            //       post: form.post,

            //       province: form.province[0],
            //       city: form.province[1],

            //       sex: form.sex,
            //       email: form.email,
            //       birthday: form.birthday
            //         ? moment(form.birthday).format("YYYY-MM-DD")
            //         : "",
            //       certStatus: form.certStatus,
            //       registerChannel: form.registerChannel,
            //       nvqUrl: "",
            //     };
            //     break;
            //   }
            //   case "1007": {
            //     data = {
            //       type: form.type,
            //       name: form.name,
            //       tel: form.tel,
            //       workUnit: form.workUnit,
            //       post: form.post,

            //       province: form.province[0],
            //       city: form.province[1],

            //       sex: form.sex,
            //       email: form.email,
            //       birthday: form.birthday
            //         ? moment(form.birthday).format("YYYY-MM-DD")
            //         : "",
            //       certStatus: form.certStatus,
            //       registerChannel: form.registerChannel,
            //       nvqUrl: "",
            //     };
            //     break;
            //   }
            //   default: {
            //     break;
            //   }
            // }
          data = {
            type: "1005", // 医学生默认
            name: form.name,
            tel: form.tel,
            school: form.school,
            education: form.education,
            specialty: form.specialty,
            province: form.province[0],
            city: form.province[1],
            sex: form.sex,
            email: form.email,
            birthday: form.birthday? moment(form.birthday).format("YYYY-MM-DD"): "",
            certStatus: form.certStatus,
            registerChannel: form.registerChannel,
            nvqUrl: "",
          };
          console.log("看看数据",data)
          //新增
          this.postList(data);
          // }
          this.addVisible = false;
          //清空
          this.selectedRowKeys = [];
          this.user_id = [];  //重置密码？
          this.$refs.addRuleForm.resetFields();

          form.birthday = undefined;
          form.sex = undefined;
          form.email = "";
          form.certStatus = "未认证";
          form.registerChannel = "后台添加";
        } else {
          return false;
        }
      });
    },
    //  新增
    async postList(data) {
      const response = await postMember(data);
      if (response.code === 0) {
        this.$message.success("新增成功~");
        //  刷新列表
        await this.getList();
      } else {
        this.$message.warning(response.message);
      }
    },
    //获取省份城市医院
    getPCH() {
      let province = [];
      let hospitalName = [];
      this.City.forEach((item, index) => {
        let city = [];
        province.push({
          value: item.name,
          label: item.name,
          children: city,
        });
        item.cityList.forEach((list) => {
          city.push({
            value: list.name,
            label: list.name,
            loading: false,
          });
        });
        province[index].children = city;
      });
      this.hospitaloPtions = province;
      this.schoolPtions = province;
    },
    async restPass(userId) {
      const response = await restPassWorld(userId);
      if (response.code === 0) {
        this.$message.success("重置密码成功，密码重置后为 111111");
        //清空
        this.selectedRowKeys = [];
        this.user_id = [];
        //刷新列表
        await this.getList();
      } else {
        this.$message.warning(response.message);
      }
    },
    hospitalSeleChange() {},
    //获取科室列表
    async getHospitalOfficesList() {
    const response = await getLoginDepartment();
    if (response.code === 0) {
      let oneOff = [];
      let list = response.data.wySy;
      list.forEach((item, index) => {
        let twoOff = [];
        oneOff.push({
          value: item.title,
          label: item.title,
          children: twoOff,
        });
        item.zoffices.forEach((lists) => {
          twoOff.push({
            value: lists.title,
            label: lists.title,
          });
        });
        oneOff[index].children = twoOff;
      });
      this.hospitalOfficesList = oneOff;
    }
    },
    officeChange(value) {
      console.log(value);
    },
    jobTitleChange(value) {
      console.log(value);
    },
    //获取医院列表
    async getHosNameList(data) {
      const response = await getHospital(data);
      if (response.code === 0) {
        this.hospitalNameList = response.data.rows;
      }
    },
    //获取职称列表
    async getJobTitleList(identify, pageNum, title) {
      //type:identify,pageNum,title
      const response = await getTitle(identify, pageNum, title);
      if (response.code === 0) {
        this.jobTitleList = response.data.row;
      }
    },
    //获取学院列表
    async getschoolList() {
      let data = {
        province: this.province,
        city: this.city,
        pagenum:this.page2num,
        pageSize:10
      };
      const response = await getSchool(data);
      //if (response.code === 0) {
      //  this.schoolListData = response.data.rows;
      //}
      if (response.code === 0) {
       let list = response.data.rows;
       for (let i = 0; i < list.length; i++) {
         this.schoolListData.push({
           id: list[i].id,
           name: list[i].name,
         });
       }
     //console.log("看列表",this.schoolListData)
     }
    },
    resetConfirmTable(id) {
      let userId = [id];
      this.restPass(userId);
    },
  }
}
</script>

<style scoped lang="scss">
.registerMember {
  width: 98%;
  display: flex;
  .registerMember-left {
    width: 10%;
  }
  .registerMember-right {
    width: 90%;
    border-left: 15px solid #f5f5f5;
    min-height: 800px;
    .registerMember-right-top {
      background-color: #f5f5f5;
      padding-top: 15px;
      width: 100%;
      height: 120px;
      border-bottom: 10px solid #f5f5f5;
      .registerMember-right-top-input {
        min-width: 25%;
        height: 50px;
        line-height: 50px;
      }
    }
    .registerMember-right-table {
      width: 100%;
      .registerMember-right-table-top-btn {
        width: 100%;
        height: 50px;
        margin-left: 20px;
        margin-top: 15px;
      }
      .registerMember-right-table-top-table {
        width: 100%;
        margin-left: 15px;
      }
    }
  }
}
.pageClass {
  width: 100%;
  text-align: right;
  float: right;
  margin: 15px 15px 20px 0;
}
.icon {
  font-size: 8px;
  margin-right: 5px;
}
::v-deep .ant-table-thead > tr > th {
    font-weight: bold;
  }
</style>