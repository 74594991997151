<template>
  <!--医学杂志浏览记录-->
  <div style="margin: -15px -15px">
    <div class="DrugCont-top">
      <div class="top_cont">
        <div class="DrugCont-top-input">
          <span>操作日期：</span>
          <a-range-picker
            style="margin-right: 15px"
            @change="onChange_range_picker"
          />
          <a-button type="primary" @click="search"> 查询 </a-button>
        </div>
        <div class="DrugCont-top-input">
          <a-button @click="expotExcel()">
            <a-icon style="color: #00a854" type="file-excel" />
            批量导出
          </a-button>
        </div>
      </div>
    </div>
    <div class="activity-table">
      <a-table
        :rowKey="(record) => record.id"
        :pagination="false"
        :columns="columns"
        :data-source="data"
      >
        <span slot="location" slot-scope="text, record">
          <span>{{ returnTitle(record.location) }}</span>
        </span>
        <span slot="title1" slot-scope="text, record">
          <a-tooltip>
            <template slot="title"> {{ text }}</template>
            <span class="multi-ellipsis">{{ text }}</span>
          </a-tooltip>
        </span>
      </a-table>
      <div
        style="
          margin-top: 28px;
          margin-left: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <span
          style="font-size: medium; color: #929292; font-size: 14px; bottom: 3%"
        >
          共{{ total }}条记录 第 {{ pageNo }} /
          {{ Math.ceil(total / pageSize) }} 页
        </span>
        <a-pagination
          style="float: right"
          show-quick-jumper
          show-size-changer
          :page-size.sync="pageSize"
          :total="total"
          v-model="pageNo"
          @change="pageChange"
          @showSizeChange="pageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getRecord, getExcel } from "@/service/magazin_api";

import codeList from "@/assets/configurationFiles/EduDrugCodeList.json";
const columns = [
  {
    title: "姓名",
    dataIndex: "name",
    key: "name",
    align: "center",
    width: "6%",
  },
  {
    title: "医院",
    dataIndex: "hospitalName",
    key: "hospitalName",
    align: "center",
    width: "10%",
  },
  {
    title: "科室",
    dataIndex: "hospitalOffices",
    key: "hospitalOffices",
    align: "center",
    width: "10%",
  },
  {
    title: "职称",
    dataIndex: "jobTitle",
    key: "jobTitle",
    align: "center",
    width: "10%",
  },
  {
    title: "杂志名称",
    dataIndex: "title",
    key: "title",
    align: "center",
    width: "26%",
    scopedSlots: { customRender: "title1" },
  },
  {
    title: "点击位置",
    dataIndex: "location",
    key: "location",
    align: "center",
    width: "10%",
    scopedSlots: { customRender: "location" },
  },
  {
    title: "操作时间",
    dataIndex: "createdTime",
    key: "createdTime",
    align: "center",
    width: "10%",
  },
];

export default {
  name: "",
  data() {
    return {
      columns,
      pageNo: 1,
      pageSize: 10,
      startTime: "",
      endTime: "",
      total: 0,
      data: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    returnTitle(code) {
      let list = codeList;
      for (let i = 0; i < list.length; i++) {
        if (code === list[i].code) {
          return list[i].title;
        }
      }
    },
    //获取接口列表
    async getList() {
      const res = await getRecord(
        this.pageNo,
        this.pageSize,
        this.startTime,
        this.endTime
      );
      if (res.code == 0) {
        this.data = res.data;
        this.total = res.count;
      }
    },
    // 分页查询
    pageChange(pageNo, pageSize) {
      this.pageNo = pageNo;
      this.pageSize = pageSize;
      this.getList();
    },
    onChange_range_picker(date, dateString) {
      this.startTime = dateString[0];
      this.endTime = dateString[1];
      this.pageSize = 10;
      this.pageNo = 1;
    },
    search() {
      this.getList();
    },
    // 导出
    async expotExcel() {
      const data = {
        startTime: this.startTime,
        endTime: this.endTime,
        ExelName: "医学杂志浏览记录",
      };
      const response = await getExcel(data);
    },
  },
};
</script>

<style scoped lang="scss">
.DrugCont-top {
  align-items: center;
  height: 100px;
  border-bottom: 15px solid #f0f2f5;
  .top_cont {
    width: 98%;
    margin: auto;
    display: flex;
    justify-content: space-between;
  }
  .DrugCont-top-input {
    height: 50px;
    line-height: 30px;
    padding-top: 30px;
  }
}
.activity-table {
  width: 98%;
  margin: auto;
  padding-top: 30px;
  padding-bottom: 30px;
}
.pageView {
  display: flex;
  justify-content: space-between;
  z-index: 3;
  margin-top: 20px;
  margin-bottom: 20px;
}
.pageLeft {
  font-size: 15px;
  color: #929292;
}
.multi-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  cursor: pointer;
}
</style>
