<template>
  <div style="margin: -15px">
    <a-spin :spinning="spinning">
      <div class="header">
        <div style="display: flex; align-items: center">
          名 称：<a-input
            placeholder="请输入"
            v-model="text"
            style="width: 250px"
            allowClear
            @pressEnter="souSuo"
          />
        </div>
        <a-button
          type="primary"
          style="margin-left: 20px; padding: 0 25px"
          @click="souSuo()"
          >搜索</a-button
        >
      </div>
      <!--      {{screeHeight -82 - 10}}-->
      <div style="border-bottom: 11px solid #f0f2f5"></div>
      <a-layout>
        <a-layout>
          <a-layout-sider style="background-color: white">
            <a-menu
              @select="selectChange"
              :selectedKeys="selectedKeys"
              :style="{ minHeight: screeHeight + 'px' }"
              mode="inline"
            >
              <a-menu-item v-for="item in menuList" :key="item.code">
                <div style="display: flex; justify-content: space-between">
                   <span>{{ item.title }}({{item.medicineFormulaNum}})</span>
                </div>
              </a-menu-item>
            </a-menu>
          </a-layout-sider>
          <a-layout-content style="background-color: white; margin-left: 8px">
            <!--           <a-button type="primary" style="margin-top: 15px;margin-left: 15px;padding: 0 25px" @click="addOpen">新增</a-button>-->
            <a-table
              :pagination="false"
              style="margin-top: 10px"
              :columns="columns"
              :data-source="tableData"
            >
              <template slot="columnName" slot-scope="text, row, index">
                <span v-for="item in row.formulaCategory">{{
                  item.title
                }}&ensp;</span>
              </template>
              <template slot="chineseName" slot-scope="text, row, index">
                <a @click="openLink(row)">{{ text }}</a>
              </template>
              <template slot-scope="text, row, index" slot="operate">
                <a-popconfirm
                  title="是否确认下架?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="release(row.id)"
                  style="margin-right: 10px"
                >
                  <a>下架</a>
                </a-popconfirm>
                <!-- <a-popconfirm
                  title="确认推荐到首页？"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="recommend(row.id)"
                  style="margin-right: 5px"
                > -->
                  <a style="margin-right: 10px" @click="openRecommend(row)">推荐</a>
                <!-- </a-popconfirm> -->
                <a @click="editOpen(row)" style="margin-right: 5px">编辑</a>
                <!--                <a-popconfirm-->
                <!--                    title="是否确认删除?"-->
                <!--                    ok-text="是"-->
                <!--                    cancel-text="否"-->
                <!--                    @confirm="removeTable(row.id)"-->
                <!--                    style="margin-right: 5px"-->
                <!--                >-->
                <!--                  <a>删除</a>-->
                <!--                </a-popconfirm>-->
                <!--                <a @click="upOrDown(row.id, 'up')" style="margin-right: 5px" v-if="index !== 0">上移</a>-->
                <!--                <a @click="upOrDown(row.id, 'down')" v-if="zlTableData.length -1 !== index" style="margin-right: 5px" >下移</a>-->
                <!--                <a-popconfirm-->
                <!--                    title="是否确认删除?"-->
                <!--                    ok-text="是"-->
                <!--                    cancel-text="否"-->
                <!--                    @confirm="zlRemove(row.id)"-->
                <!--                >-->
                <!--                  <a>删除</a>-->
                <!--                </a-popconfirm>-->
              </template>
            </a-table>
            <div
              style="
                margin-top: 30px;
                padding-left: 20px;
                display: flex;
                justify-content: center;
              "
            >
              <!--            <span style="font-size: 17px;color: #929292">共 {{ pagination.total }} 条记录 第 {{ pagination.pageNum }} / {{-->
              <!--                Math.ceil(pagination.total / pagination.pageSize)-->
              <!--              }} 页</span>-->
              <a-pagination
                v-model="pagination.pageNum"
                :page-size="pagination.pageSize"
                :total="pagination.total"
                show-quick-jumper
                show-size-changer
                style="margin-bottom: 40px"
                @change="pageChange"
                @showSizeChange="pageChange"
              >
              </a-pagination>
            </div>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </a-spin>
    <a-drawer
      placement="right"
      :visible="addVisible"
      :width="600"
      title="新增"
      @close="addClose"
    >
      <a-form-model
        ref="addForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="所属分类" prop="columnCode">
          <a-select
            v-model="form.formulaCategory"
            placeholder="请选择"
            mode="multiple"
          >
            <!-- <a-select-option value="0">
              常用公式
            </a-select-option>
            <a-select-option value="DWHS">
              单位换算
            </a-select-option> -->

            <a-select-option v-for="item in typeList" :value="item.code">
              {{ item.title }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="中文名称" prop="chineseName">
          <a-input v-model="form.chineseName" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="英文名称" prop="englishName">
          <a-input v-model="form.englishName" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="说明" prop="title2">
          <a-table
            :locale="{ emptyText: '暂无数据' }"
            size="small"
            :pagination="false"
            :columns="SMColumns"
            :data-source="form.explain"
          >
            <span slot="customTitle"
              ><span style="color: red">*</span> 名称</span
            >
            <span slot="zhi"><span style="color: red">*</span> 值</span>
            <template slot-scope="text, row, index" slot="customTitle">
              <a-input
                style="width: 100px"
                v-model="row.title"
                placeholder="请输入"
                v-if="form.SMStatus"
              />
              <span v-else>{{ row.title }}</span>
            </template>
            <template slot="zhi" slot-scope="text, row, index">
              <a v-if="form.SMStatus" @click="setOpen(index, 'add', row.value)"
                >设置</a
              >
              <a v-else disabled>设置</a>
            </template>
            <template slot-scope="text, row, index" slot="caozuo">
              <a-popconfirm
                title="是否确认删除?"
                ok-text="是"
                cancel-text="否"
                @confirm="SMRemove(index, 'form')"
                v-if="form.SMStatus"
              >
                <a>删除</a>
              </a-popconfirm>
              <a v-else disabled>删除</a>
            </template>
          </a-table>
          <template v-if="form.SMStatus">
            <a-button
              block
              style="margin-top: 5px"
              type="dashed"
              @click="addSMRow('form')"
              >+ 添加</a-button
            >
            <a-button
              style="float: right; margin-top: 5px"
              type="primary"
              @click="addSMSave"
              >保存</a-button
            >
          </template>
          <a-button
            v-else
            style="float: right; margin-top: 5px"
            type="primary"
            @click="form.SMStatus = true"
            >编辑</a-button
          >
        </a-form-model-item>

        <a-form-model-item label="参考文献" prop="title2">
          <a-table
            :locale="{ emptyText: '暂无数据' }"
            size="small"
            :pagination="false"
            :columns="WXColumns"
            :data-source="form.references"
          >
            <span slot="customTitle"
              ><span style="color: red">*</span> 名称</span
            >
            <template slot-scope="text, row, index" slot="customTitle">
              <a-input
                style="width: 100px"
                v-model="row.title"
                placeholder="请输入"
                v-if="form.WXStatus"
              />
              <span v-else>{{ row.title }}</span>
            </template>
            <template slot="link" slot-scope="text, row, index">
              <a-input
                style="width: 100px"
                v-model="row.link"
                placeholder="请输入"
                v-if="form.WXStatus"
              />
              <span v-else>{{ row.link }}</span>
            </template>
            <template slot-scope="text, row, index" slot="caozuo">
              <a-popconfirm
                title="是否确认删除?"
                ok-text="是"
                cancel-text="否"
                @confirm="SMRemove(index, 'form')"
                v-if="form.SMStatus"
              >
                <a>删除</a>
              </a-popconfirm>
              <a v-else disabled>删除</a>
            </template>
          </a-table>
          <template v-if="form.WXStatus">
            <a-button
              block
              style="margin-top: 5px"
              type="dashed"
              @click="addSetWXRow('form')"
              >+ 添加</a-button
            >
            <a-button
              style="float: right; margin-top: 5px; margin-bottom: 10px"
              type="primary"
              @click="addSetWXSave"
              >保存</a-button
            >
          </template>
          <a-button
            v-else
            style="float: right; margin-top: 5px; margin-bottom: 10px"
            type="primary"
            @click="form.WXStatus = true"
            >编辑</a-button
          >
        </a-form-model-item>
      </a-form-model>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="addClose">
          取消
        </a-button>
        <a-button type="primary" @click="addOk"> 提交 </a-button>
      </div>
    </a-drawer>

    <a-drawer
      placement="right"
      :visible="editStatus"
      :width="600"
      title="编辑"
      @close="editClose"
    >
      <a-form-model
        ref="editForm"
        :model="edForm"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="所属分类" prop="formulaCategory">
          <a-select
            v-model="edForm.formulaCategory"
            placeholder="请选择"
            mode="multiple"
          >
            <a-select-option v-for="item in typeList" :value="item.code">
              {{ item.title }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="中文名称" prop="chineseName">
          <a-input v-model="edForm.chineseName" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="英文名称" prop="englishName">
          <a-input v-model="edForm.englishName" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="说明" prop="title2">
          <a-table
            :locale="{ emptyText: '暂无数据' }"
            size="small"
            :pagination="false"
            :columns="SMColumns"
            :data-source="edForm.explain"
          >
            <span slot="customTitle"
              ><span style="color: red">*</span> 名称</span
            >
            <span slot="zhi"><span style="color: red">*</span> 值</span>
            <template slot-scope="text, row, index" slot="customTitle">
              <a-input
                style="width: 100px"
                v-model="row.title"
                placeholder="请输入"
                v-if="edForm.SMStatus"
              />
              <span v-else>{{ row.title }}</span>
            </template>
            <template slot="zhi" slot-scope="text, row, index">
              <a
                v-if="edForm.SMStatus"
                @click="setOpen(index, 'edit', row.value)"
                >设置</a
              >
              <a v-else disabled>设置</a>
            </template>
            <template slot-scope="text, row, index" slot="caozuo">
              <a-popconfirm
                title="是否确认删除?"
                ok-text="是"
                cancel-text="否"
                @confirm="SMRemove(index, 'edForm')"
                v-if="edForm.SMStatus"
              >
                <a>删除</a>
              </a-popconfirm>
              <a v-else disabled>删除</a>
            </template>
          </a-table>
          <!--          {{count}}-->
          <!--          {{edForm.SMStatus}}-->
          <template v-if="edForm.SMStatus">
            <a-button
              block
              style="margin-top: 5px"
              type="dashed"
              @click="addSMRow('edForm')"
              >+ 添加</a-button
            >
            <a-button
              style="float: right; margin-top: 5px"
              type="primary"
              @click="editSMSave"
              >保存</a-button
            >
          </template>
          <a-button
            v-else
            style="float: right; margin-top: 5px"
            type="primary"
            @click="aaw"
            >编辑</a-button
          >
        </a-form-model-item>

        <a-form-model-item label="参考文献" prop="title2">
          <a-table
            :locale="{ emptyText: '暂无数据' }"
            size="small"
            :pagination="false"
            :columns="WXColumns"
            :data-source="edForm.references"
          >
            <span slot="customTitle"
              ><span style="color: red">*</span> 名称</span
            >
            <template slot-scope="text, row, index" slot="customTitle">
              <a-input
                style="width: 100px"
                v-model="row.title"
                placeholder="请输入"
                v-if="edForm.WXStatus"
              />
              <span v-else>{{ row.title }}</span>
            </template>
            <template slot="link" slot-scope="text, row, index">
              <a-input
                style="width: 100px"
                v-model="row.link"
                placeholder="请输入"
                v-if="edForm.WXStatus"
              />
              <span v-else>{{ row.link }}</span>
            </template>
            <template slot-scope="text, row, index" slot="caozuo">
              <a-popconfirm
                title="是否确认删除?"
                ok-text="是"
                cancel-text="否"
                @confirm="WXRemove(index, 'edForm')"
                v-if="edForm.WXStatus"
              >
                <a>删除</a>
              </a-popconfirm>
              <a v-else disabled>删除</a>
            </template>
          </a-table>
          <template v-if="edForm.WXStatus">
            <a-button
              block
              style="margin-top: 5px"
              type="dashed"
              @click="addSetWXRow('edForm')"
              >+ 添加</a-button
            >
            <a-button
              style="float: right; margin-top: 5px; margin-bottom: 10px"
              type="primary"
              @click="editSetWXSave"
              >保存</a-button
            >
          </template>
          <a-button
            v-else
            style="float: right; margin-top: 5px; margin-bottom: 10px"
            type="primary"
            @click="editEdit"
            >编辑</a-button
          >
        </a-form-model-item>
        <a-form-model-item
            label="研发人员"
            prop="developMembers"
            style="margin-bottom:100px"
          >
            <a-select
              mode="multiple"
              v-model="edForm.developMembers"
              placeholder="请选择"
              show-search
            >
              <a-select-option
                v-for="item in yanfaList"
                :value="item"
              >
                {{ item }}
              </a-select-option>
            </a-select>
        </a-form-model-item>
      </a-form-model>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="editClose">
          取消
        </a-button>
        <a-button type="primary" @click="editOk"> 提交 </a-button>
      </div>
    </a-drawer>

    <a-modal
      :zIndex="1009"
      :width="900"
      v-model="addSetModal"
      title="设置"
      @ok="addSetOk"
      @cancel="addModelCancel"
    >
      <div>
        <Toolbar
          style="border-bottom: 1px solid #ccc"
          :editor="editor"
          :defaultConfig="toolbarConfig"
          :mode="mode"
        />
        <Editor
          style="overflow-y: hidden; height: 400px"
          v-model="html"
          :defaultConfig="editorConfig"
          :mode="mode"
          @onCreated="onCreated"
        />
      </div>
    </a-modal>

    <!-- 推广抽屉 -->
    <a-drawer
        title="推广"
        placement="right"
        :closable="false"
        :visible="promotionShow"
        @close="promotionCancel"
        width="30%"
      >
        <!-- 社区状态按钮 -->
        <a-table
          :row-key="(record) => record.id"
          :pagination="false"
          :columns="communityColumns"
          :data-source="communityData"
          bordered 
        >
          <span slot="is_open" slot-scope="text, record">
            <a-switch
              :checked="recommendState !== 0"
              v-model="record.is_open"
              checked-children="是"
              un-checked-children="否"
              @click="FormulaListSwitch(record.is_open)"
              default-checked
            />
          </span>
        </a-table>
      </a-drawer>
  </div>
</template>

<script>
import E from "wangeditor";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import axios from "axios";
import TcVod from "vod-js-sdk-v6";
import {
  delFormulaRemoveApi,
  getColumnVodsList,
  getFormulaApi,
  getFormulaReleaseApi,
  getUndercarrigeApi,
  postFormulaApi,
  putFormulaApi,
} from "@/service/MedicalConference_y";
import qdHeader from "@/service/api_header_config";
import { getType1, getMenuList,postRecommend,delRecommendList } from "@/service/MedicalFormulas_api";
const editorConfig = {
  // JS 语法
  MENU_CONF: {},
};
const signature = "";
// 修改 uploadImage 菜单配置
editorConfig.MENU_CONF["uploadImage"] = {
  // 自定义上传
  async customUpload(file, insertFn) {
    let formData = new FormData();
    formData.append("file", file);
    // file 即选中的文件,自己实现上传，并得到图片 url alt href
    axios
      .post(
        "https://yun-new-admin-api.yunicu.com/api/admin/common/update",
        formData
      )
      .then((res) => {
        if (res.data.code === 0) {
          insertFn(res.data.data.url); // 最后插入图片
        }
      });
  },
};
// 修改 uploadVideo 菜单配置
editorConfig.MENU_CONF["uploadVideo"] = {
  // 自定义上传
  async customUpload(file, insertFn) {
    let mediaFile = file;
    //获取签名
    axios
      .get("https://yun-new-admin-api.yunicu.com/v3/getUgcUploadSign")
      .then((res) => {
        if (res.data.code === 0) {
          const getSignature = () => {
            return res.data.data.signature;
          };
          const tcVod = new TcVod({
            getSignature: getSignature, //上传签名的函数
          });
          const uploader = tcVod.upload({
            mediaFile: mediaFile, // 媒体文件（视频或音频或图片），类型为 File
          });
          uploader.done().then((res) => {
            insertFn(res.video.url); // 进行视频处理：转码和截图
          });
        }
      });
  },
};
export default {
  name: "PendingListing",
  components: { Editor, Toolbar },
  data() {
    return {
      yanfaList: ['冯明霞','邓换媚','韩广志','李凌','张灯珍','韩笑'],
      FormulaListId:'',
      communityColumns: [
        {
          title: "模块",
          key: "title",
          dataIndex: "title",
        },
        {
          title: "状态",
          dataIndex: "is_open",
          key: "is_open",
          scopedSlots: { customRender: "is_open" },
        },
      ],
      communityData: [
        {
          id: 1,
          title: "医学公式首页",
          is_open: false,
        },
      ],
      promotionShow:false,
      formulaHeader: qdHeader.formula_header,
      selectedKeys: [""],
      editStatus: false,
      editor: null,
      html: "",
      toolbarConfig: {},
      editorConfig,
      mode: "default", // or 'simple'
      addSetModal: false,
      typeList: [],
      SMColumns: [
        {
          slots: { title: "customTitle" },
          scopedSlots: { customRender: "customTitle" },
        },
        {
          width: 60,
          align: "center",
          slots: { title: "zhi" },
          scopedSlots: { customRender: "zhi" },
        },
        {
          width: 70,
          title: "操作",
          scopedSlots: { customRender: "caozuo" },
        },
      ],
      WXColumns: [
        {
          slots: { title: "customTitle" },
          scopedSlots: { customRender: "customTitle" },
        },
        {
          title: "链接",
          scopedSlots: { customRender: "link" },
        },
        {
          width: 70,
          title: "操作",
          scopedSlots: { customRender: "caozuo" },
        },
      ],
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
      rules: {
        chineseName: [{ required: true, message: "请输入", trigger: "blur" }],
        columnCode: [{ required: true, message: "请选择", trigger: "blur" }],
        formulaCategory:[{ required: true, message: "请选择", trigger: "blur" }],
      },
      form: {
        columnCode: undefined,
        SMStatus: false,
        WXStatus: false,
        explain: [],
        references: [],
      },
      addVisible: false,
      pagination: {
        //存储大table的分页
        total: 0,
        pageSize: 10,
        pageNum: 1,
      },
      tableData: [],
      columns: [
        {
          title: "code",
          dataIndex: "code",
          align: "center",
        },
        {
          title: "所属类别",
          scopedSlots: { customRender: "columnName" },
          align: "center",
        },
        {
          title: "中文名称",
          dataIndex: "chineseName",
          scopedSlots: { customRender: "chineseName" },
          align: "center",
        },
        {
          title: "英文名称",
          dataIndex: "englishName",
          align: "center",
        },
        {
          title: "发布人",
          dataIndex: "issueName",
        },
        {
          title: "发布时间",
          dataIndex: "issueTime",
        },
        {
          title: "研发人员",
          dataIndex: "developMembers",
        },
        {
          title: "操作",
          align: "center",
          scopedSlots: { customRender: "operate" },
        },
      ],
      spinning: false,
      text: "",
      screeHeight: document.body.clientHeight - 82 - 64 - 30 - 10.4,
      setIndex: null,
      edForm: {
        SMStatus: false,
        WXStatus: false,
      },
      menuList: [],
      count: 0,
      setType: null,
      recommendState:0,
    }; //dataReturn
  },
  created() {
    this.getMenuList();
    this.getTableData();
    this.getTypeList();
  },
  methods: {
    //  推广弹窗取消
    promotionCancel() {
      this.promotionShow = false;
    },
    async FormulaListSwitch(isOpen) {
      if (isOpen) {
        const data = {
          medicineFormulaId: this.FormulaListId,
        };
        const response = await postRecommend(data);
        if (response.code === 0) {
          this.$message.success("推广成功");
          await this.getTableData();
          this.getMenuList();
        } else {
          this.$message.warning(response.message);
        }
      } else {
        const response = await delRecommendList(this.FormulaListId);
        if (response.code === 0) {
          this.$message.success("关闭成功");
          await this.getTableData();
          this.getMenuList();
        } else {
          this.$message.warning(response.message);
        }
      }
    },
    async openRecommend(row){
      this.promotionShow = true
      this.FormulaListId = row.id
      if(row.is_recommend === 0){
        this.communityData[0].is_open = false;
        this.recommendState == 0
      }else{
        this.communityData[0].is_open = true;
        this.recommendState == 1
      }
      // let data = {
      //   medicineFormulaId:id
      // }
      // const res = await postRecommend(data);
      // if (res.code === 0) {
      //   this.$message.success("推荐成功");
      //   if (this.tableData.length === 1 && this.pagination.pageNum > 1) {
      //     this.pagination.pageNum--;
      //   }
      //   await this.getTableData();
      //   this.getMenuList();
      // } else {
      //   this.$message.warning(res.message);
      // }
    },
    openLink(row) {
      // const memberId = JSON.parse(window.localStorage.getItem('user_info')).id
      window.open(
        `${this.formulaHeader}/formula/${row.code}?memberId=c2d242a0-f979-11ea-aed5-33e01bcc3a0a&channel=admin&location=YXGS_ADMIN&id=${row.id}`
      );
    },
    async removeTable(id) {
      const data = {
        id,
      };
      this.spinning = true;
      const res = await delFormulaRemoveApi(data);
      if (res.code === 0) {
        this.$message.success("删除成功");
        if (this.tableData.length === 1 && this.pagination.pageNum > 1) {
          this.pagination.pageNum--;
        }
        await this.getTableData();
      } else {
        this.$message.warning(res.message);
      }
    },
    async release(id) {
      const data = {
        id,
      };
      this.spinning = true;
      const res = await getUndercarrigeApi(data);
      if (res.code === 0) {
        this.$message.success("下架成功");
        if (this.tableData.length === 1 && this.pagination.pageNum > 1) {
          this.pagination.pageNum--;
        }
        await this.getTableData();
        this.getMenuList();
      } else {
        this.$message.warning(res.message);
      }
    },
    souSuo() {
      this.pagination.pageNum = 1;
      this.getTableData();
    },
    editEdit() {
      this.edForm.WXStatus = true;
      this.edForm = Object.assign({}, this.edForm, this.edForm);
    },
    aaw() {
      this.edForm.SMStatus = true;
      this.edForm = Object.assign({}, this.edForm, this.edForm);
      // this.count ++
      // console.log(this.edForm)
      // console.log('点击了')
    },
    async getTableData() {
      this.spinning = true;
      const data = {
        name: this.text,
        pageNo: this.pagination.pageNum,
        pageSum: this.pagination.pageSize,
        columnCode: this.selectedKeys[0],
        isIssue: 1,
      };
      const res = await getFormulaApi(data);
      if (res.code === 0) {
        this.tableData = res.data;
        this.pagination.total = res.count;
        this.spinning = false;
      }
    },
    async getMenuList() {
      const res = await getMenuList(1);
      if (res.code === 0) {
        // const index = res.data.findIndex((item) => item.code === "YUN_ICU");
        // res.data.splice(index, 1);
        this.menuList = res.data;
        console.log(this.menuList);
      }
    },
    selectChange(e) {
      this.selectedKeys = e.selectedKeys;
      this.pagination.pageNum = 1;
      this.text = "";
      this.getTableData();
    },
    editOpen(row) {
      this.edForm = JSON.parse(JSON.stringify(row));
      const arr = [];
      this.edForm.formulaCategory.forEach((item) => {
        arr.push(item.code);
      });

      this.edForm.formulaCategory = arr;
      if(row.developMembers && row.developMembers!==''){
        this.edForm.developMembers = row.developMembers.split('、');
      }else{
        this.$set(this.edForm,'developMembers',[]);
      }
      // this.edForm.WXStatus = false
      // this.edForm.SMStatus = false
      // setTimeout(() => {
      this.editStatus = true;
      // },1100)
      console.log(this.edForm);
    },
    editClose() {
      this.editStatus = false;
    },
    addSetWXRow(type) {
      this[type].references.push({
        name: "",
      });
    },
    addSMRow(type) {
      this[type].explain.push({
        name: "",
      });
    },
    editOk() {
      this.$refs.editForm.validate(async (vlid) => {
        if (vlid) {
          const status = this.edForm.SMStatus || this.edForm.WXStatus;
          if (status) {
            this.$message.warning("请先保存待编辑状态的内容");
            return;
          }
          this.edForm.categorysCode = [...this.edForm.formulaCategory];
          delete this.edForm.formulaCategory;
          const data = {
            ...this.edForm,
          };
          delete data.WXStatus;
          delete data.SMStatus;
          data.developMembers = this.edForm.developMembers !== undefined ? this.edForm.developMembers.join('、'):this.edForm.developMembers
          const res = await putFormulaApi(data);
          if (res.code === 0) {
            this.$message.success("修改成功");
            await this.getTableData();
            this.editStatus = false;
             this.getMenuList();
          } else {
            this.$message.warning(res.message);
          }
        } else {
          this.$message.warning("请填写完整表单");
        }
      });
    },
    addOk() {
      this.$refs.addForm.validate(async (vlid) => {
        if (vlid) {
          const status = this.form.SMStatus || this.form.WXStatus;
          if (status) {
            this.$message.warning("请先保存待编辑状态的内容");
            return;
          }
          this.spinning = true;
          const data = {
            columnCode: this.form.columnCode[0],
            chineseName: this.form.chineseName,
            englishName: this.form.englishName,
            explain: this.form.explain,
            references: this.form.references,
          };
          const res = await postFormulaApi(data);
          if (res.code === 0) {
            this.$message.success("新增成功");
            await this.getTableData();
            this.addVisible = false;
          } else {
            this.$message.warning(res.message);
          }
        } else {
          this.$message.warning("请填写完整表单");
        }
      });
    },

    addSetWXSave() {
      const list = this.form.references;
      for (let i = 0; i < list.length; i++) {
        if (!list[i].title || list[i].title.trim().length === 0) {
          this.$message.warning("必填项不能为空");
          return;
        }
      }
      if (list.length > 0) this.$message.success("保存成功");
      this.form.WXStatus = false;
    },
    editSetWXSave() {
      const list = this.edForm.references;
      for (let i = 0; i < list.length; i++) {
        if (!list[i].title || list[i].title.trim().length === 0) {
          this.$message.warning("必填项不能为空");
          return;
        }
      }
      if (list.length > 0) this.$message.success("保存成功");
      this.edForm.WXStatus = false;
      this.edForm = Object.assign({}, this.edForm, this.edForm);
    },
    editSMSave() {
      const list = this.edForm.explain;
      for (let i = 0; i < list.length; i++) {
        if (
          !list[i].title ||
          list[i].title.trim().length === 0 ||
          !list[i].value ||
          list[i].value.trim().length === 0 ||
          list[i].value === "<p><br></p>"
        ) {
          this.$message.warning("必填项不能为空");
          return;
        }
      }
      if (list.length > 0) this.$message.success("保存成功");
      this.edForm.SMStatus = false;
    },
    addSMSave() {
      const list = this.form.explain;
      for (let i = 0; i < list.length; i++) {
        if (
          !list[i].title ||
          list[i].title.trim().length === 0 ||
          !list[i].value ||
          list[i].value.trim().length === 0 ||
          list[i].value === "<p><br></p>"
        ) {
          this.$message.warning("必填项不能为空");
          return;
        }
      }
      if (list.length > 0) this.$message.success("保存成功");
      this.form.SMStatus = false;
    },
    setOpen(index, type, text) {
      this.setIndex = index;
      if (type === "add") {
        this.html = text;
        this.addSetModal = true;
        this.setType = "add";
      } else {
        this.html = text;
        this.addSetModal = true;
        this.setType = "edit";
      }
    },
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    addSetOk() {
      if (this.setType === "add") {
        this.form.explain[this.setIndex].value = this.html;
      } else {
        // console.log(this.edForm.explain[this.setIndex])
        this.edForm.explain[this.setIndex].value = this.html;
      }
      this.addSetModal = false;
    },
    addModelCancel() {
      this.addSetModal = false;
    },
    WXRemove(index, type) {
      this[type].references.splice(index, 1);
    },
    SMRemove(index, type) {
      this[type].explain.splice(index, 1);
    },
    addOpen() {
      this.addVisible = true;
      this.$refs.addForm.resetFields();
      this.form = {
        columnCode: undefined,
        SMStatus: false,
        WXStatus: false,
        explain: [],
        references: [],
      };
      if (this.selectedKeys[0].trim().length > 0)
        this.form.columnCode = this.selectedKeys;
    },
    addClose() {
      this.addVisible = false;
    },
    pageChange(page_no, page_size) {
      this.pagination.pageNum = page_no;
      this.pagination.pageSize = page_size;
      this.getTableData();
    },
    // 获取类别
    async getTypeList() {
      const res = await getType1(1, 999);
      if (res.code == 0) {
        this.typeList = res.data;
      }
    },
  },
};
</script>

<style scoped>
.header {
  padding: 25px;
  width: 100%;
  display: flex;
  align-items: center;
  /*border-bottom: 10px solid #F0F2F5;*/
}
</style>
